import React from 'react'

const Faq = (data) => {
	const questions = [
		{
			question: "What is Mini Exhibitions?",
			answer: (
				<div>
					<p>Mini Exhibitions is a full-service event provider for remote teams.</p>
					<p>We organize memorable experiences for your team – ranging from culinary classes, magic shows, art classes, games, celebrity speaking engagements, and more – to help your remote colleagues build bonds, strengthen connections, and have fun together.</p>
				</div>
			) 
		}, {
			question: "How does it work?",
			answer: (
				<div>
					<p>Mini Exhibitions members retain us for monthly, bi-monthly, or quarterly event programming – to ensure that there is a steady cadence of engaging activities for your team year-round.</p>
					<p>Some of our member clients prefer to plan out their events months or even a full year in advance, while others receive occasional communications from us (monthly, bi-monthly, quarterly) to plan new events throughout the year.</p>
				</div>
			) 
		}, {
			question: "How does pricing work?",
			answer: (
				<div>
					<p>Our events are priced flat-rate depending on which membership tier (monthly, bi-monthly, quarterly) you subscribe to.</p>
					<p>Some events have required or suggested add-ons (for instance: cocktail kits, lock-picking kits, grocery deliveries) that are billed separately, per-attendee.</p>
				</div>
			) 
		}, {
			question: "Who are your clients?",
			answer: (
				<div>
					<p>We typically work with fast-growing, remote teams – including mature technology companies like Amazon and Spotify, large startups like Stripe and Artsy, and numerous early-stage startups that are building out their remote teams.</p>
				</div>
			) 
		}, {
			question: "Why is it important to do events at all?",
			answer: (
				<div>
					<p>Remote event planning often falls by the wayside – especially when it is the responsibility of an overburdened founder, manager, or people ops pro – but regular bonding activities can be critical for hiring new employees, retaining current employees, and keeping morale high.</p>
				</div>
			) 
		}
	]
	
	return (
		<div>
			{questions.map((item, i) => { return(
					<div key={i}>
						<h3 style={{margin: '3rem 0 0 0'}}>
							{item.question}
						</h3>
						{item.answer}
					</div> 
				)
			})}
		</div>
		
	)
}
export default Faq 