import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

const initialState = {
	status: 'Idle',
  error: null,
	team: {
		title:      	null,
		date: 	      null,
		time: 	      null,
    description:  null,
    attendees:    {}
	}
}

export const fetchTeam = createAsyncThunk('teams/fetchTeam', async (teamId) => {
  const response = await api.get(`/teams/${teamId}`)
	// console.log("API response: ") 
	// console.log(response) 
  return response
})

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setTeam(state, action) {
      state.team = action.payload
    },
  },
  extraReducers: {
    [fetchTeam.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchTeam.fulfilled]: (state, action) => {
			const data = action.payload.data
			state.status = 'Success'
			state.team = data.team
    },
    [fetchTeam.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
  }
})

export const selectTeam = state => state.team
// export const setTeam = state => state.team

export const { setTeam } = teamSlice.actions
export default teamSlice.reducer