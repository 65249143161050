import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  submitNewRequest,
} from '../../features/inviteRequests/inviteRequestSlice'
import { broadcastEvent } from '../../features/tracking/userEventSlice';

// TODO update with new link
const typeformLink = 'https://art-in-res.typeform.com/to/R3EFnDE3'
const defaultButtonText = 'Request an invite'

const SuccessfulSubmission = () => {
  return(
    <div style={{maxWidth: '24rem', margin: '0 auto'}}>
      <p>Thanks! We'll be in touch.</p>
      <p>In the mean time, you can <a href={typeformLink} target="_blank">click here</a> to tell us a little more about what you're looking for.</p>
    </div>
  )
}

const ClickableLink = (data) => {
  const buttonText = data.buttonText ? data.buttonText : defaultButtonText
  return (
    <a href='#' style={{color: 'inherit', borderBottom: '4px solid black'}}>
      {buttonText}      
    </a>
  )
}

const SubmittableButton = (data) => {
	const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const onEmailChanged = e => setEmail(e.target.value)
  const requestStatus = useSelector(state => state.inviteRequest.status)
	
  const buttonText = data.buttonText ? data.buttonText : defaultButtonText
  const evData = data.evData ? data.evData : null 

  const type = data.type ? data.type : 'RemoteEventsInviteRequest'

	const styles = {}

	const buttonStyles = {
		...styles,
	}

  const formClassName = data.className ? data.className : 'email-capture'
  
	const onSubmitAttempt = async (e) => {
		e.preventDefault()

    if (canSubmit) {
      try {
        const resultAction = await dispatch(
          submitNewRequest({ email, type })
        )
      } catch (err) {
        console.error('Failed to submit invite request: ', err)
      }
      if (evData) {
        dispatch(broadcastEvent(evData))
      }
    }
  }

  const canSubmit = [email].every(Boolean) && requestStatus === 'Idle'

	return (
		<form className={formClassName}>
      <input 
        name="email"
        type="email"
        placeholder="Email address"
				disabled={requestStatus==='Pending'} 
				style={styles}
        onChange={onEmailChanged}
      />
      <button 
				type="submit" 
				status={requestStatus} 
				disabled={requestStatus==='Pending' || requestStatus==='Success'} 
				style={buttonStyles}
				onClick={onSubmitAttempt}
			>
				{buttonText}
			</button>
    </form>
	)
}
const EmailCaptureButton = (data) => {
  const requestStatus = useSelector(state => state.inviteRequest.status)
  
  const buttonText = data.buttonText ? data.buttonText : defaultButtonText
  const successWidget = data.successWidget ? data.successWidget : <SuccessfulSubmission />
  const evData = data.evData ? data.evData : null 

  switch (requestStatus) {
    case 'Success':
      return(successWidget)
    case 'Idle':
      return(<SubmittableButton buttonText={buttonText} evData={evData} className={data.className} />)
    default:
      return(<ClickableLink />)
      
  }
}

export default EmailCaptureButton