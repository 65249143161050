import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import { openModal } from '../../features/modals/modalSlice'
import Spinner from '../../components/Spinner'
import { fetchTriviaLeagueTeam, submitNewTeamMember, resetMemberStatus } from '../../features/triviaLeagueTeams/triviaLeagueTeamSlice'
import AttendeeList from '../interactiveEventPages/AttendeeList'
import { TriviaTitleWithHorns, TriviaLeagueHeader, TriviaThemeBackground, TriviaIntroBlurb, TriviaHowItWorks, TriviaFaq } from './TriviaLeagueHome'	

const registerTeamPath = "/trivia-league/register"

const SignUpShower = ({ clickHandler }) => {
	return(
		<div style={{margin: '3rem auto'}}>
			<a href="#" className="button" onClick={clickHandler} style={{marginBottom: '0'}}>
			 	Register to be on this team
			</a>
		</div>
	)	
}

const formFieldStyles = { 
	width: '100%', 
	margin: '0 0 1rem 0',
	padding: '1rem',
	fontSize: '1.5rem',
}

const StandardForm = ({ teamId }) => {
	const dispatch = useDispatch()

	// Standard
	const nameValue = useSelector(state => state.attendance.newAttendance.name)
	// const companyValue = useSelector(state => state.attendance.newAttendance.company)
	const emailValue = useSelector(state => state.attendance.newAttendance.email)
	
	// Standard
	const [name, setName] = useState(nameValue);
	// const [company, setCompany] = useState(companyValue);
	const [newEmail, setNewEmail] = useState(emailValue);
	
	const [message, setMessage] = useState('');
	const [buttonText, setButtonText] = useState('Join this team');
	
	// Standard
	const handleNameChange = e => {
		setName(e.target.value)
	}
	// const handleCompanyChange = e => {
	// 	setCompany(e.target.value)
	// }
	const handleEmailChange = e => {
		setNewEmail(e.target.value)
	}
	
	const submitFormData = (e) => {
		e.preventDefault()
		
		dispatch(submitNewTeamMember({
			full_name: name,
			email: newEmail,
			team_id: teamId,
		}))
	}

	console.log("teamId: ")
	console.log(teamId)
	return (
		<div>
			<h3 style={{marginBottom: '2.25rem'}}>Join this team</h3>
			<p>{message}</p>
			<form onSubmit={submitFormData}>
				<input onChange={handleNameChange} type="text" name="name" placeholder="Your name" style={formFieldStyles} required></input>
				<br />
				<input onChange={handleEmailChange} type="text" name="email" placeholder="Your email" style={formFieldStyles} required></input>
				<br />

				<input type="submit" value={buttonText} className="button" style={{margin: '1rem auto'}}></input>
			</form>
		</div>
	)
}



const JoinTeamModal = ({ setModalOpen }) => {
	const dispatch = useDispatch()
	const teamId = useParams()
	
	const loadStatus = useSelector(state => state.triviaLeagueTeam.memberStatus)
	
	const handleCloseClick = (e) => {
		setModalOpen(false);
		dispatch(resetMemberStatus())
	}

	const reset = (e) => {
		e.stopPropagation();
		dispatch(resetMemberStatus())
	}

	const stopProp = (e) => { e.stopPropagation(); }

	const states = ['Idle', 'Success', 'Error']
	
	const SuccessMessage = () => {
		return (
			<div>
				<h1>Success!</h1>
				<p>You registered to be on this team!</p>
			</div>
		)
	}
	
	const ErrorMessage = () => {
		return (
			<div>
				<h1>Whoops!</h1>
				<p>Uh oh, something went wrong there.</p>
				<p>Shoot us an email or, if you want:</p>
				<a href='#' className='button' onClick={reset}>Try again</a>
			</div>
		)
	}
	
	const content = { 
		Idle: <StandardForm teamId={teamId.teamId} />,
		// Idle: <ErrorMessage />,
		Pending: (<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner /></div>),
		Success: <SuccessMessage />,
		Error: <ErrorMessage />,
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 

	return (
		<div className='modal-overlay night' onClick={handleCloseClick}>
			<div className='modal' onClick={stopProp}>
				<div style={{
					width: '100%',
				}}>
					<div className='close-x' onClick={handleCloseClick}>&times;</div>

					{content}
		
				</div>
			</div>
		</div>
	)
}

const TriviaTeamShareableSignUpForm = () => {
	const { teamId } = useParams();
	const dispatch = useDispatch()
	
	const [modalOpen, setModalOpen] = useState(false)
  
	const loadStatus = useSelector(state => state.triviaLeagueTeam.status)
  const error = useSelector(state => state.triviaLeagueTeam.error)
	const team = useSelector(state => state.triviaLeagueTeam.team)
	
	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchTriviaLeagueTeam(teamId))
    }
  }, [loadStatus, dispatch])
	
	const handleRegisterTeamClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}
	
	const members      = ( team || {} ).members      || []
	const coordinators = ( team || {} ).coordinators || [] 

	return (
		<div>
			<Header style='night' />
			<div className='container night'>
				<h1 style={{}}>{team.name}</h1>
				<p>Team sign-up page for</p>
				<TriviaTitleWithHorns />
				
				<SignUpShower clickHandler={handleRegisterTeamClick} />

				<div style={{maxWidth: '32rem', margin: '0 auto'}}>
					<div style={{margin: '0 auto', textAlign: 'center'}}>
						<AttendeeList name={"Coordinators"} list={coordinators} showTitle={false} />
					</div>
					<div style={{margin: '0 auto', textAlign: 'center'}}>
						<AttendeeList name={"Members"} list={members} showTitle={false} />
					</div>
				</div>	
				{ modalOpen ? <JoinTeamModal setModalOpen={setModalOpen} /> : '' }
			</div>
			<TriviaThemeBackground />
		</div>
	)
}
export default TriviaTeamShareableSignUpForm 