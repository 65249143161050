import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

// TODO - organize this better
const initialState = {
	status: 'Idle',
  error: null,
	eventArchetypes: [],
	eventArchetype: {
		name:      	 null,
    description: null,
	}
}

// List
export const fetchEventArchetypes = createAsyncThunk('eventArchetypes/fetchEventArchetypes', async () => {
  const response = await api.get('/event_archetypes')
  return response
})

// Individual 
export const fetchEventArchetype = createAsyncThunk('eventsArchetypes/fetchEventArchetype', async (eventArchetypeId) => {
  const response = await api.get(`/event_archetypes/${eventArchetypeId}`)
  return response
})

const eventArchetypesSlice = createSlice({
  name: 'eventArchetypes',
  initialState,
  reducers: {
    setEventArchetypes(state, action) {
      state.eventArchetypes = action.payload
    },
  },
  extraReducers: {
    // List 
    [fetchEventArchetypes.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchEventArchetypes.fulfilled]: (state, action) => {
			const data = action.payload.data
			state.status = 'Success'
      // TODO - append
			state.eventArchetypes = data.event_archetypes
    },
    [fetchEventArchetypes.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
   
    // Individual
    [fetchEventArchetype.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchEventArchetype.fulfilled]: (state, action) => {
			const data = action.payload.data
			state.status = 'Success'
			state.eventArchetype = data.event_archetype
    },
    [fetchEventArchetype.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
  }
})

export const selectEventArchetype  = state => state.eventArchetypes.eventArchetype
export const selectEventArchetypes = state => state.eventArchetypes.eventArchetypes

export const { setEventArchetypes } = eventArchetypesSlice.actions
export default eventArchetypesSlice.reducer