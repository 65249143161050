import React from 'react'
import Header from '../navigation/Header.js' 
import Footer from '../navigation/Footer.js' 
import SocialProofBar from '../salesWidgets/SocialProofBar'
import EmailCaptureButton from '../salesWidgets/EmailCaptureButton'

import img_bartender from '../../images/illustrations/bartender-no-bg.svg' 
import programSlice from '../../features/events/programSlice.js'

// image: 'https://miniex-public.s3.us-west-1.amazonaws.com/manual-uploads/gifs/simpsons-art-class.webp',

const featureData = [
  {
    heading: 'Unique and surprising events',
    text: 'We offer 100+ events ranging from mixology and cooking classes, art classes, magic shows, trivia… and events you didn’t even know you wanted like lockpicking, beekeeping, rock stacking, classical Pilates, and live-hosted spelling bees.',
    image: 'https://miniex-public.s3.us-west-1.amazonaws.com/manual-uploads/gifs/elephant-painting.webp',
  }, {
    heading: 'Events for seasons and celebrating diversity',
    text: 'We have events for different holidays and diversity initiatives – so you can celebrate everyone at your company. And if you have a particular theme in mind, we’re always happy to customize an event for you, too.',
    image: 'https://miniex-public.s3.us-west-1.amazonaws.com/manual-uploads/gifs/pumkin-hed.webp',
  }, {
    heading: 'Flat pricing no matter how many people show up',
    text: 'We’ll quote you a flat price per event. You don’t have to worry about whether too many or too few people show up – you know your price ahead of time. With us, you won’t have to waste time on your end going back and forth over budget and headcount.',
    image: 'https://miniex-public.s3.us-west-1.amazonaws.com/manual-uploads/gifs/simpsons-auditorium.webp'
  }, {
    heading: 'We do the administrative work for you',
    text: 'We’ll create calendar events, we’ll send reminder emails. We’ll coordinate with your team to find a time that works. You can always choose to do these things on your end, but we’re ready to roll our sleeves up and do as much of the work as you’re comfortable to pass on to us.',
    image: 'https://miniex-public.s3.us-west-1.amazonaws.com/manual-uploads/gifs/dog-at-computer.webp',
  }, {
    heading: 'We love global teams',
    text: 'If your team is spread across the world, it’s no sweat for us. We work with lots of global teams – we’ll find times that work for everyone, handle shipping, and offer events that are perfect for international teams.',
    image: 'https://miniex-public.s3.us-west-1.amazonaws.com/manual-uploads/gifs/planet-emoji.webp',
  }, {
    heading: 'Shared slack channels',
    text: 'We love shared Slack channels for running polls, sharing photos, answering questions, pre-event reminders, and hyping up our next event. And if you need to speak with someone on our team, we’re just a Slack message away.',
    image: 'https://miniex-public.s3.us-west-1.amazonaws.com/manual-uploads/gifs/aol.gif',
  },
]

const SuccessfulEmailSubmission = () => {
	const typeformLink = 'https://art-in-res.typeform.com/to/R3EFnDE3'
  return(
    <div>
      <p>Thanks! We'll be in touch.</p>
      <p style={{fontSize: '2rem'}}>
				In the mean time, you can <a href={typeformLink} target="_blank">click here</a> to tell us a little more about what you're looking for.
			</p>
    </div>
  )
}

const CtaButton = () => {
	const evData = {
		noun: "get list of events button",
		verb: "click",
	}

	return (
		<EmailCaptureButton 
		  buttonText="Get the list of events" 
			successWidget={<SuccessfulEmailSubmission />}
			evData={evData}
		/>
	)
}

const FeatureWidget = (props) => {
  const TextPart = () => {
    return (
      <div>
        <h1 style={{marginTop: 0}}>
          {props.heading}
        </h1>
        <p className='hide-mobile'>
          {props.text}
        </p>
      </div>
    )
  }
  const MoblieTextPart = () => {
    return (
      <div className='mobile-only'>
        <p>
          {props.text}
        </p>
      </div>
    )
  }
  
  const ImgPart = (imgProps) => {
    return (
      <div className={imgProps.className} style={{textAlign: 'center'}}>
        <img src={props.img} style={{maxWidth: '100%'}} />
      </div>
    )
  }

  const LeftVersion = () => {
    const styl = {
      gridTemplateColumns: '2fr 1.5fr'
    }
    return (
      <div className='inner-grid' style={styl}>
        <TextPart />
        <ImgPart />
      </div>
    )
  }
  const RightVersion = () => {
    const styl = {
      gridTemplateColumns: '1.5fr 2fr'
    }
    return (
      <div className='inner-grid' style={styl}>
        <ImgPart className='hide-mobile' />
        <TextPart />
        <ImgPart className='mobile-only' />
      </div>
    )
  }

  return (
    <div className='feature-widget'>
      { props.flipped ? <RightVersion /> : <LeftVersion /> }
      <MoblieTextPart />
    </div>
    
  )
}

const HeroSection = () => {
  return(
    <div className='hero' style={{
      position: 'relative',
      textAlign: 'center',
      padding: '12vh 0 2rem 0',
      // backgroundImage: `url(${img_bartender})`,
      // backgroundSize: 'cover',
      // backgroundPosition: 'center',
    }}>   
      {/* Foreground */}
      <div style={{
        maxWidth: '600px',
        margin: '0 auto',
        padding: '3rem 2rem',
      }}>
        {/* Header */}
        <div style={{}}>
          <h1 style={{
            // fontSize: '2.5rem',
            margin: '0.5rem 0',
            lineHeight: '1.25', 
          }}>
            <div style={{margin: '0 0 0.5rem 0'}}>
              Throw your team an amazing remote event.
            </div>
            <div>
              Sit back while we do all the work.
            </div>
          </h1>
        </div>
        {/* Subheader */}
        <div>
          <p style={{}}>
            Mini Exhibitions is a membership program for remote events. 
            We create calendar events, send reminder emails, collect team members shipping addresses and ship them goodies &mdash; all so you don't have to.
          </p>
        </div>
        {/* Call-to-action button */}
        <div>
          <CtaButton />
        </div>
      </div>
    </div>
  )
}

const LandingRedux = () => {
	const colors = {
		black: '#222222',
		semiVibrantBlue: '#0e28d6',
		peachOffWhite: '#fffaf4',
	}

	return (
		<div>
			<Header />
			<div className='landingJuly2022'>
        <HeroSection />
        <div style={{
          maxWidth: '800px',
          margin: '0 auto',
        }}>
          <SocialProofBar />

          <br />
          <br />

          <div className='features-section'>
            {featureData.map((item, i) => {
              const flipped = i % 2 === 1

              return(
                <FeatureWidget heading={item.heading} text={item.text} img={item.image} flipped={flipped}/>
              )
            })}
          </div>

          <div>
            <CtaButton />
          </div>
          <br />        
          <Footer />
        </div>
			</div>	
		</div>
	)
}

export default LandingRedux 