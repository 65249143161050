import { combineReducers } from 'redux'

// import authReducer from './features/auth/authSlice'
import modalReducer from './features/modals/modalSlice'
import eventReducer from './features/events/eventSlice'
import eventArchetypesReducer from './features/eventArchetypes/eventArchetypesSlice'
import programReducer from './features/events/programSlice'
import teamReducer from './features/teams/teamSlice'
import attendanceReducer from './features/attendance/attendanceSlice'
import hostingReducer from './features/attendance/hostingSlice'
import presentingReducer from './features/attendance/presentingSlice'
import inviteRequestSlice from './features/inviteRequests/inviteRequestSlice'
import surveySlice from './features/surveys/surveySlice'
import referralCodeSlice from './features/referrals/referralCodeSlice'
import hostInvitationReducer from './features/events/hostInvitationSlice'
import presenterInvitationReducer from './features/events/presenterInvitationSlice'
import triviaLeagueTeamReducer from './features/triviaLeagueTeams/triviaLeagueTeamSlice'

const rootReducer = combineReducers({
	inviteRequest: inviteRequestSlice,
	survey: surveySlice,
  referralCodes: referralCodeSlice,
	modals: modalReducer,
	event: eventReducer,
	eventArchetypes: eventArchetypesReducer,
	program: programReducer,
	team: teamReducer,
  attendance: attendanceReducer,
  hosting: hostingReducer,
  hostInvitation: hostInvitationReducer,
  presenting: presentingReducer,
  presenterInvitation: presenterInvitationReducer,
	triviaLeagueTeam: triviaLeagueTeamReducer,
	// auth: authReducer,
})

export default rootReducer