import React from 'react'
import { useCalendlyEventListener, PopupButton } from "react-calendly";
import { broadcastEvent } from '../../features/tracking/userEventSlice';
import { useDispatch } from 'react-redux'

export const CalendlyEventListener = (props) => {
	const dispatch = useDispatch()
 
  const broadcastButtonClick = () => {
    let evData = {
      noun: "book a call button",
      verb: "click",
    }
    if (props.referralCode) {
      evData.payload = { referralCode: props.referralCode }
    }

    dispatch(broadcastEvent(evData))
  }
  
  const broadcastDnTSelected = () => {
    let evData = {
      noun: "calendly timeslot",
      verb: "click",
    }
    if (props.referralCode) {
      evData.payload = { referralCode: props.referralCode }
    }

    dispatch(broadcastEvent(evData))
  }
  
  const broadcastMeetingBooked = (e) => {
    let evData = {
      noun: "sales call",
      verb: "book",
      payload: e.data.payload
    }

    dispatch(broadcastEvent(evData))
  }

  useCalendlyEventListener({
    onEventTypeViewed: broadcastButtonClick,
    onDateAndTimeSelected: broadcastDnTSelected, // time selected
    onEventScheduled: (e) => broadcastMeetingBooked(e),
  })

  return (null)
}

export const CalendlyButton = (props) => {	
  const buttonText = props.buttonText ? props.buttonText : "Book a call"
	const calendlyUrl = props.calendlyUrl ? props.calendlyUrl : "https://calendly.com/miniexhibitions/chat"
  
  return (
    <PopupButton
      url={calendlyUrl}
      rootElement={document.getElementById("root")}
      text={buttonText}
      textColor="#e4e0f3"
      color="#e4e0f3"
      className="big"
    />
  )
}

export default CalendlyButton