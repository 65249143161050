import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEvent, selectEvent, submitNewTeam } from '../../features/events/eventSlice'
// import { submitNewTeam } from '../../features/triviaLeagueTeams/triviaLeagueTeamSlice'
import { useParams } from "react-router-dom"
import { openModal } from '../../features/modals/modalSlice'
import { WrappedGif } from '../../components/Gif.js'
import AddAttendeeModal from './AddAttendeeModal'
import JoinTeamModal from './JoinTeamModal.js'
import AttendeeList from './AttendeeList'
import Spinner from '../../components/Spinner'

const formFieldStyles = { 
	width: '100%', 
	margin: '0 0 1rem 0',
	padding: '1rem',
	fontSize: '1.5rem',
}

const SignUpShower = ({children, ...props}) => {
	const dispatch = useDispatch()

	const showSignUp = (e) => {
		e.preventDefault();
		dispatch(openModal('signUp'));
	}

	return (
		<a href="#" onClick={showSignUp}>{children}</a>
	)
}

const AddAttendeeButton = ({ clickHandler }) => {
	return(
		<div>
			<a href="#" className="button" onClick={clickHandler} style={{marginBottom: '0'}}>
				I want to attend this event
			</a>
			<br />
			<p style={{fontSize: '0.875rem'}}>(You can sign someone else up, too)</p>
		</div>
	)	
}

const TeamRoster = ({ team, eventId }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const handleJoinTeamClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}

	// TODO add other members
	const allMembers = team.coordinators.concat(team.members)
	const listItems = allMembers.map((user, i) =>
		<li style={{listStyle: 'none'}} key={user.id}>{user.name}</li>
	);
	return (
		<div style={{marginBottom: '1rem' }}>
			<h3 style={{marginBottom: '0.5rem' }}>{team.name}</h3>
			<ul style={{marginTop: '0.5rem' }}>
				{listItems}
			</ul>
			<a href="#" onClick={handleJoinTeamClick}  className="button tiny">
			 	Join {team.name} 
			</a>
			{ modalOpen ? <JoinTeamModal team={team} eventId={eventId} setModalOpen={setModalOpen} /> : '' }
		</div>
	)
}

const TeamList = ({ teams, eventId }) => {
	const teamEntries = teams.map((team, i) =>
		<TeamRoster key={team.id} team={team} eventId={eventId} />
	)
	
	return (
		<div className='grid-2'>
			{teamEntries}	
		</div>
	)
}

const RegisterTeamButton = ({ clickHandler }) => {
	return(
		<div style={{margin: '3rem auto'}}>
			<a href="#" className="button" onClick={clickHandler} style={{marginBottom: '0'}}>
			 	Register a new team
			</a>
		</div>
	)	
}

const RegisterTeamForm = ({ eventId }) => {
	const dispatch = useDispatch()

	// Standard
	const nameValue = useSelector(state => state.attendance.newAttendance.name)
	const companyValue = useSelector(state => state.attendance.newAttendance.company)
	const emailValue = useSelector(state => state.attendance.newAttendance.email)
	
	// Standard
	const [name, setName] = useState(nameValue);
	const [company, setCompany] = useState(companyValue);
	const [newEmail, setNewEmail] = useState(emailValue);
	
	const [message, setMessage] = useState('');
	const [buttonText, setButtonText] = useState('Begin registration');
	
	// Standard
	const handleNameChange = e => {
		setName(e.target.value)
	}
	const handleCompanyChange = e => {
		setCompany(e.target.value)
	}
	const handleEmailChange = e => {
		setNewEmail(e.target.value)
	}
	
	const submitEmails = (e) => {
		e.preventDefault()
		
		dispatch(submitNewTeam({
			event_id: eventId,
			name: company,
			does_trivia: false,
			guest: true,
			coordinator: {
				full_name: name,
				email: newEmail,
			} 
		}))
	}
	
	return (
		<div>
			<h3 style={{marginBottom: '2.25rem'}}>Register your team</h3>
			<p>{message}</p>
			<form onSubmit={submitEmails}>
				<input onChange={handleNameChange} type="text" name="name" placeholder="Your name" style={formFieldStyles} required></input>
				<br />
				<input onChange={handleEmailChange} type="text" name="email" placeholder="Your email" style={formFieldStyles} required></input>
				<br />
				<input onChange={handleCompanyChange} type="text" name="company" placeholder="Your company" style={formFieldStyles} required></input>
				<br />

				<input type="submit" value={buttonText} className="button" style={{margin: '1rem auto'}}></input>
			</form>
			<p style={{lineHeight: '1.1'}}>
				{/* When you click that button, you'll get a URL you can share with other folks at your company to register to be on the same team. */}
				After you register, you'll be sent your unique team URL to share with your colleagues.
			</p>
		</div>
	)
}
const MeetingLink = ({ meeting_url }) => {
	return(
		<div>
			<a href={meeting_url} className="button" target="_blank">
				Open meeting
			</a>
		</div>
	)	
}

const RegisterTeamModal = ({ setModalOpen, eventId }) => {
	const dispatch = useDispatch()
	
	// const loadStatus = useSelector(state => state.triviaLeagueTeam.status)
	const loadStatus = useSelector(state => state.event.api.teamRegistrationStatus)
	const team = useSelector(state => state.triviaLeagueTeam.team)

	const handleCloseClick = (e) => {
		setModalOpen(false);
	}

	const reset = (e) => {
		e.stopPropagation();
	}

	const stopProp = (e) => { e.stopPropagation(); }

	const states = ['Idle', 'Success', 'Error']
	
	const SuccessMessage = () => {
		// const teamUrl = `${config.websiteRoot}/trivia-league/register/${team.id}`

		return (
			<div>
				<h1>Success!</h1>
				<p>Your team was registered.</p>
				<p>What's next?<br />Send your teammates to:</p>
				{/* <a href={teamUrl} className='button'>Your team URL</a> */}
			</div>
		)
	}
	
	const ErrorMessage = () => {
		return (
			<div>
				<h1>Whoops!</h1>
				<p>Uh oh, something went wrong there.</p>
				<p>Shoot us an email or, if you want:</p>
				<a href='#' className='button' onClick={reset}>Try again</a>
			</div>
		)
	}
	
	const content = { 
		Idle: <RegisterTeamForm eventId={eventId} />,
		// Idle: <ErrorMessage />,
		Pending: (<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner /></div>),
		Success: <SuccessMessage />,
		Error: <ErrorMessage />,
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 

	return (
		<div className='modal-overlay' onClick={handleCloseClick}>
			<div className='modal' onClick={stopProp}>
				<div style={{
					width: '100%',
				}}>
					<div className='close-x' onClick={handleCloseClick}>&times;</div>

					{content}
		
				</div>
			</div>
		</div>
	)
}

const TeamRegEventContainer = ({ eventData, gifUrl, showMeetingUrl, attendees, guestTeams, eventId }) => {
	// const dispatch = useDispatch()
	const [modalOpen, setModalOpen] = useState(false)
	const handleAddAttendeeClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}
	return (
		<div className='container white border'>
			<Helmet>
				<title>{eventData.event.title} | Mini Exhibitions</title>
			</Helmet>
			<h1>{eventData.event.title}</h1>
			<p>{eventData.event.brief_desc}</p>
			<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
				<div>
					<dt>Date</dt>
					<dd>{eventData.event.date}</dd>
				</div>
				
				<div>
					<dt>Time</dt>
					<dd>{eventData.event.time}</dd>
				</div>
			</dl>
			
			<br />
			{gifUrl ? <WrappedGif url={gifUrl} /> : ''}
			<br />
			{ showMeetingUrl ? <MeetingLink meeting_url={eventData.event.meeting_url} /> : '' }
			{/* <RegisterTeamButton clickHandler={handleAddAttendeeClick} />
			<small>(Or join an existing team below)</small> */}
			
			<h2>About this event</h2>
			<div style={{whiteSpace: "pre-wrap", maxWidth: '600px', margin: '0 auto', textAlign: 'left'}}>
				{eventData.event.description}
			</div>

			<br />
			
			<h2>
				Teams
			</h2>
			<div style={{margin: '0 auto', textAlign: 'center'}}>
				<TeamList name={"Teams"} teams={guestTeams} eventId={eventId} />
				{/* <AttendeeList name={"Attendees"} list={attendees} showTitle={false} /> */}
			</div>
			{ showMeetingUrl ? <MeetingLink meeting_url={eventData.event.meeting_url} /> : '' }
			<RegisterTeamButton clickHandler={handleAddAttendeeClick} />
			{ modalOpen ? <RegisterTeamModal eventId={eventId} setModalOpen={setModalOpen} /> : '' }
		</div>
	)
}


const StandardEventContainer = ({ eventData, gifUrl, showMeetingUrl, attendees, eventId }) => {
	// const dispatch = useDispatch()
	const [modalOpen, setModalOpen] = useState(false)
	const handleAddAttendeeClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}
	return (
		<div className='container white border'>
			<Helmet>
				<title>{eventData.event.title} | Mini Exhibitions</title>
			</Helmet>
			<h1>{eventData.event.title}</h1>
			<p>{eventData.event.brief_desc}</p>
			<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
				<div>
					<dt>Date</dt>
					<dd>{eventData.event.date}</dd>
				</div>
				
				<div>
					<dt>Time</dt>
					<dd>{eventData.event.time}</dd>
				</div>
			</dl>
			
			<br />
			{gifUrl ? <WrappedGif url={gifUrl} /> : ''}
			<br />
			{ showMeetingUrl ? <MeetingLink meeting_url={eventData.event.meeting_url} /> : '' }
			<AddAttendeeButton clickHandler={handleAddAttendeeClick} />
			
			<h2>Event Description</h2>
			<div style={{whiteSpace: "pre-wrap", maxWidth: '600px', margin: '0 auto', textAlign: 'left'}}>
				{eventData.event.description}
			</div>

			<br />
			
			<h2>
				RSVPs
			</h2>
			<div style={{margin: '0 auto', textAlign: 'center'}}>
				<AttendeeList name={"Additional Attendees"} list={attendees} showTitle={false} />
			</div>
			{ showMeetingUrl ? <MeetingLink meeting_url={eventData.event.meeting_url} /> : '' }
			<AddAttendeeButton clickHandler={handleAddAttendeeClick} />
			{ modalOpen ? <AddAttendeeModal eventId={eventId} setModalOpen={setModalOpen} useAddressForm={eventData.event.requires_addresses} /> : '' }
		</div>
	)
}

const ShareableEventPage = () => {
	const { eventId } = useParams();
	const dispatch = useDispatch()
	const eventData = useSelector(selectEvent)
	const [modalOpen, setModalOpen] = useState(false)
	const states = ['Success', 'Error']
  
  const loadStatus = useSelector(state => state.event.status)
  const error = useSelector(state => state.event.error)

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchEvent(eventId))
    }
  }, [loadStatus, dispatch])

	// const presenters = (( eventData.event || {} ).attendees || [] ).presenters
	// const moderators = (( eventData.event || {} ).attendees || [] ).moderators
	const attendees = (( eventData.event || {} ).attendees || {} ).attendees
	const guestTeams = (( eventData.event || {} ).teams || {} ).guests
	console.log("guestTeams: ")
	console.log(guestTeams)

	const ml = eventData.event.meeting_url
	// const showMeetingUrl = typeof(ml) === 'string' && ml.length > 0 
	const showMeetingUrl = eventData.event.show_meeting_url 
	const gifUrl = eventData.event.gif_url

	return {
 		Success: (
			 <div>
				<Header />
				{
					eventData.event.team_prereg ?
						<TeamRegEventContainer  eventData={eventData} gifUrl={gifUrl} showMeetingUrl={showMeetingUrl} attendees={attendees} guestTeams={guestTeams} eventId={eventId} /> :
						<StandardEventContainer eventData={eventData} gifUrl={gifUrl} showMeetingUrl={showMeetingUrl} attendees={attendees} eventId={eventId} />
				}
				
				
			</div>
		),
		Pending: (
			<div>
				<Header />
				<Helmet>
					<title>Mini Exhibitions | Loading...</title>
				</Helmet>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<div>
				<Header />
				<Helmet>
					<title>Event not found | Mini Exhibitions</title>
				</Helmet>
				<h1>Hmm, we didn't find an event RSVP for you here 🤔</h1>
			</div>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}
export default ShareableEventPage