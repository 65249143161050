import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import Footer from '../navigation/Footer.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { 
	fetchSurvey, 
	submitSurveyAnswer,
	selectAnswerOption, 
	advanceActiveQuestionId, 
	recedeActiveQuestionId,
	markFinished,
} from '../../features/surveys/surveySlice.js'
import { useParams } from "react-router-dom"

const PrevLink = () => {
	const dispatch = useDispatch()
	const handleClick = (e) => {
		e.preventDefault();
		dispatch(recedeActiveQuestionId());
	}
	return (
		<div>
			<a href="#" onClick={handleClick}>Prev</a> 
		</div>
	)
}
const NextLink = () => {
	const dispatch = useDispatch()
	const handleClick = (e) => {
		e.preventDefault();
		dispatch(advanceActiveQuestionId());
	}
	return (
		<div>
			<a href="#" onClick={handleClick}>Next</a> 
		</div>
	)
}
const NextButton = () => {
	const dispatch = useDispatch()
	const handleClick = (e) => {
		e.preventDefault();
		dispatch(advanceActiveQuestionId());
	}
	return (
		<div style={{margin: '1rem auto'}}>
			<button onClick={handleClick}>Next</button> 
		</div>
	)
}
const FinishButton = () => {
	const dispatch = useDispatch()
	const handleClick = (e) => {
		e.preventDefault();
		dispatch(markFinished());
	}
	return (
		<div style={{margin: '1rem auto'}}>
			<button onClick={handleClick}>Finish</button> 
		</div>
	)
}

const AnswerOption = ({ answerOption, ids, position, questionCount }) => {
	const dispatch = useDispatch()
	const answerData = {
		answer_option_id: answerOption.id,
		question_id: ids.questionId,
		// content: TODO, 
	}
	const [timer, setTimer] = useState(0);

	const advanceFunc = (position === questionCount) ? markFinished : advanceActiveQuestionId

	const handleClick = (e) => {
		e.preventDefault();
		dispatch(submitSurveyAnswer({ id: ids.surveyId, answerData: answerData}));
		dispatch(selectAnswerOption(answerData));
    clearTimeout(timer)
		setTimer(setTimeout(() => {
			dispatch(advanceFunc());
		}, 300))
	}

	const cssClasses = ['option']
	if (answerOption.selected) { cssClasses.push("selected") }
	const className = cssClasses.join(" ") 

	return (
		<li className={className} onClick={handleClick} style={{}}>
			{answerOption.wording}
		</li>
	)
}

const AnswerOptionSet = ({ answerOptions, ids, position, questionCount }) => {
	return (
		<ul style={{
			textAlign: 'left',
		}}>
			{answerOptions.map((item, i) => { 
				return(<AnswerOption key={item.id} answerOption={item} ids={ids} position={position} questionCount={questionCount} />) 
			})}
		</ul>
	)
}

const TextOnlyAnswerField = ({ ids, position, questionCount }) => {
	const dispatch = useDispatch()
	
	const [timer, setTimer] = useState(0);
	const [content, setContent] = useState('');
		
	const handleChange = e => {
		const textVal = e.target.value
		const answerData = {
			question_id: ids.questionId,
			content: textVal, 
		}
		
		setContent(textVal)
    clearTimeout(timer)
		setTimer(setTimeout(() => { 
			console.log(e.target.value) 
			dispatch(submitSurveyAnswer({ id: ids.surveyId, answerData: answerData}));
		}, 500))
	}

	const taStyles = {
		width: '32rem',
		maxWidth: '100%',
		padding: '0.5rem',
		fontSize: '1.25rem',
	}
	
	return (
		<div>
			<textarea onChange={handleChange} name="answer" placeholder="Type your answer..." style={taStyles} rows={5} />
			{position === questionCount ? '' : <NextButton /> }
		</div>
	)
}

const Question = ({ question, position, activeQuestionId, questionCount, surveyId }) => {
	// const question = data.question
	const displayVal = question.id === activeQuestionId ? 'flex' : 'none'
	const answerOptions = question.answer_options
	const hasOptions = ( question.answer_options || []).length > 0

	const outerStyles = {
		boxSizing: 'border-box',
		height: '100vh',
		width: '100vw',
		display: displayVal,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
		padding: '2rem',
	}
	const innerStyles = {
		maxWidth: '100%',
		width: '40rem',
		margin: '0 auto',
	}
	const navStyles = {
		fontSize: '80%',
    position: 'fixed',
    bottom: '1rem',
		display: 'flex',
		alignItems: 'center',
	}

	const ids = {
		questionId: question.id,
		surveyId: surveyId,
	}

	return (
		<div className="survey-question" style={outerStyles}>
			<div style={innerStyles}>
				<p>{question.wording}</p>
				{
					hasOptions ? 
						<AnswerOptionSet answerOptions={answerOptions} ids={ids} position={position} questionCount={questionCount} /> : 
						<TextOnlyAnswerField ids={ids} position={position} questionCount={questionCount} />
				}	
			</div>
			{position === questionCount ? <FinishButton /> : ''}
			<div style={navStyles}>
				{position === 1 ? '' : <PrevLink />}
				<div style={{margin: '0 1rem'}}>
					<span style={{fontSize: '125%'}}>{position}</span> 
					&ensp;of&ensp;
					<span style={{fontSize: '125%'}}>{questionCount}</span>
				</div>
				{position === questionCount ? '' : <NextLink />}
			</div>
		</div>
	)
}

const QuestionSet = ({ questions, questionCount, activeQuestionId, surveyId }) => {
	return (
		questions.map((item, i) => { return(
				<Question
					key={item.id}
					position={i+1}
					question={item} 
					activeQuestionId={activeQuestionId} 
					questionCount={questionCount} 
					surveyId={surveyId} 
				/>
			)
		})
	)
}

const FinishedPanel = () => {
	const outerStyles = {
		boxSizing: 'border-box',
		display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
		padding: '2rem',
	}
	const innerStyles = {
		maxWidth: '100%',
		width: '40rem',
		margin: '0 auto',
	}
	return (
		<div style={outerStyles}>
			<div style={innerStyles}>
				<p>Thank you!!</p>
				<p>This feedback really helps us ❤️</p>
			</div>
		</div>
		
	)
} 

const SurveyPage = () => {
	const { surveyId } = useParams();
	const dispatch = useDispatch()
	const survey = useSelector(state => state.survey)
  
	const loadStatus = useSelector(state => state.survey.fetchStatus)
  const error = useSelector(state => state.survey.error)
  const finished = useSelector(state => state.survey.finished)
  const questions = useSelector(state => state.survey.questions)
  const activeQuestionId = useSelector(state => state.survey.activeQuestionId)
  const questionCount = questions.length
	
	const states = ['Success', 'Error']

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchSurvey(surveyId))
    }
  }, [loadStatus, dispatch])

	return {
 		Success: (
			 <div>
        <Helmet>
          <title>Mini Exhibitions | Survey</title>
        </Helmet>
				<Header />

        <div className='container wide'>
					{ finished ? 
							<FinishedPanel /> :
							<QuestionSet questions={questions} activeQuestionId={activeQuestionId} questionCount={questionCount} surveyId={surveyId} /> 
					}
        </div>
			</div>
		),
		Pending: (
			<div>
				<Header />
				<Helmet>
					<title>Mini Exhibitions | Survey</title>
				</Helmet>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<div>
				<Header />
				<Helmet>
					<title>Mini Exhibitions | Survey</title>
				</Helmet>
				<h1>Hmm, we weren't able to your survey... 🤔</h1>
			</div>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}
export default SurveyPage 