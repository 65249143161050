import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { config } from '../../config'

const apiRoot = config.apiRoot

// Security by obscurity
const getAuthToken = () => { return window.localStorage.pausePosition }

// TODO reduce duplication
const api = {
  root: apiRoot,

  getAuthToken: getAuthToken,

  get: (path) => {
    const authTok = getAuthToken() 
    const headies = {
      Authorization: authTok
    }
    const url = `${apiRoot}${path}`
    //console.log("url: " + url)

    const req = axios.get(url, { headers: headies })

    return req
  },

  post: (path, payload) => {
    const authTok = getAuthToken() 
    const headies = {
      Authorization: authTok
    }

    const req = axios.post(
      `${apiRoot}${path}`, 
      payload,
      { headers: headies }
    )

    return req
  },

  put: (path, payload) => {
    const authTok = getAuthToken() 
    const headies = {
      Authorization: authTok
    }

    const req = axios.put(
      `${apiRoot}${path}`, 
      payload,
      { headers: headies }
    )

    return req
  }
}

//window.api = api

export default api
