// Adapted from https://redux.js.org/usage/configuring-your-store/
// import { applyMiddleware, compose, createStore } from 'redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk'

import loggerMiddleware from './middleware/consoleLogger'
import rootReducer from './reducer'

export default function configureStore(preloadedState) {
  const middlewares = [ thunkMiddleware ]
	if (process.env.NODE_ENV !== 'production') {
		middlewares.push(loggerMiddleware)
	}

  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [ middlewareEnhancer ]
  // const composedEnhancers = compose(...enhancers)
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(rootReducer))
  }

  return store
}