import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

const initialState = {
	status: 'Idle',
  error: null,
  api: {
    teamRegistrationStatus: 'Idle',
    memberStatus: 'Idle',
    eventFetchStatus: 'Idle',
  },
	event: {
		title:      	null,
		date: 	      null,
		time: 	      null,
    description:  null,
    attendees:    {}
	}
}

export const fetchEvent = createAsyncThunk('events/fetchEvent', async (eventId) => {
  const response = await api.get(`/events/${eventId}`)
	// console.log("API response: ") 
	// console.log(response) 
  return response
})

export const submitNewTeam = createAsyncThunk(
  'events/submitNew',
  async teamInfo => {
    const response = await api.post('/teams', { team: teamInfo })
    return response.data
  }
)

export const submitNewTeamMember = createAsyncThunk(
  'events/submitNewMember',
  async memberInfo => {
    console.log("memberInfo: ")
    console.log(memberInfo)
    const response = await api.post(`/teams/${memberInfo.team_id}/member`, { member: memberInfo })
    return response.data
  }
)

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent(state, action) {
      state.event = action.payload
    },
  },
  extraReducers: {
    [fetchEvent.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchEvent.fulfilled]: (state, action) => {
			const data = action.payload.data
			state.status = 'Success'
			state.event = data.event
    },
    [fetchEvent.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
    // Submit team (create) 
    [submitNewTeam.pending]: (state, action) => {
      state.api.teamRegistrationStatus = 'Pending'
    },
    [submitNewTeam.fulfilled]: (state, action) => {
      console.log("action.payload: ")
      console.log(action.payload)
			const teamData = action.payload.team
      state.api.teamRegistrationStatus = 'Success'
			// state.team = data
      state.event.teams.guests = state.event.teams.guests.concat(teamData)
    },
    [submitNewTeam.rejected]: (state, action) => {
      state.api.teamRegistrationStatus = 'Error'
      state.error = action.error.message
    },
    
    // Submit new team member (create) 
    [submitNewTeamMember.pending]: (state, action) => {
      state.api.memberStatus = 'Pending'
    },
    [submitNewTeamMember.fulfilled]: (state, action) => {
			const data = action.payload.team
			state.api.memberStatus = 'Success'
			state.team = data
    },
    [submitNewTeamMember.rejected]: (state, action) => {
      state.api.memberStatus = 'Error'
      state.error = action.error.message
    },
  }
})

export const selectEvent = state => state.event
// export const setEvent = state => state.event

export const { setEvent } = eventSlice.actions
export default eventSlice.reducer