import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import { openModal } from '../../features/modals/modalSlice'
import Spinner from '../../components/Spinner'
import { submitNewTeam } from '../../features/triviaLeagueTeams/triviaLeagueTeamSlice'
import { config, contactLinks } from '../../config'
import TriviaThemeColors from './TriviaThemeColors'

export const TriviaThemeBackground = () => {
	return (
		<div style={{
			position: 'fixed',
			top: '0', right: '0', bottom: '0', left: '0', zIndex: '-1',
			backgroundColor: TriviaThemeColors.vibrantPeriwinkleButtonBg1, 
			background: `linear-gradient(to right, ${TriviaThemeColors.vibrantPeriwinkleButtonBg1} 0%, ${TriviaThemeColors.vibrantPeriwinkleButtonBg2} 100%)`,
		}}>

		</div>
	)
}

export const TriviaLeagueHeader = () => {
	return (
		<div style={{maxWidth: '32rem', margin: '0 auto'}}>
			<h1>🤓&ensp;Startup Trivia League&ensp;🤓</h1>
		</div>
	)
}

export const TriviaIntroBlurb = () => {
	return (
		<div style={{maxWidth: '32rem', margin: '0 auto'}}>
			<p style={{fontSize: '3rem', margin: '0 auto'}}>
				📜 
			</p>
			<p>
				Since the dawn of time, humankind has engaged in contests for glory… the distinction of being victorious!
			</p>

			<p>
				We're excited to announce an inter-company version of our best-selling virtual event &ndash; our live-hosted team trivia game &ndash; for the most competitive, the pedantic, the lovers of esoterica and cherishers of factual correctness.
			</p>

			<p>
				As always, it will be a delightful evening of friendly competition, a little trash talk, competing alongside your coworkers, exercising your brain, and just having a good time.
			</p>
		</div>
	)
}

const BigCenteredEmojiDiv = (data) => {
	const divStyles = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '3rem'
	}
	if (( data || {} ).flip) {
		divStyles.transform = 'scaleX(-1)'
	}

	return (
		<div style={divStyles}>
			🤘
		</div>
	)
}

export const TriviaTitleWithHorns = () => {
	return (
		<div style={{
			display: 'grid',
			gridTemplateColumns: '1fr 4fr 1fr',
		}}>
			<BigCenteredEmojiDiv />
			<div>
				<h1 style={{marginTop: '1rem', textTransform: 'uppercase'}}>Startup<br />Trivia League</h1>
			</div>
			<BigCenteredEmojiDiv flip={true} />
		</div>
	)
}

export const TriviaIntroRealWayPreamble = () => {
	return (
		<div style={{maxWidth: '32rem', margin: '4rem auto'}}>
			<p>“Unicorn” ?</p>
			<p>“Decacorn” ???</p> 
			<p>“Product-market fit” ??????</p>
			<br />

			<p>The real way to win at startups…<br />is&ensp;<span style={{fontSize: '1.5rem', fontWeight: '700'}}>trivia</span>.</p>
			<br />

			<p style={{marginBottom: '0'}}>Announcing:</p>
			
			<TriviaTitleWithHorns />
			
		</div>
	)
}

export const TighterPitch = () => {
	const ulStyles = {
		textAlign: 'left',
		listStyle: 'disc'
	}

  return (
		<div style={{maxWidth: '32rem', margin: '0 auto'}}>
			<p>Meet new people, square off against other startups, and show the world how wrinkled your brains are in the only game that matters: <i>trivia</i>.</p>
			{/* <p>Show the world how wrinkled your brains are by squaring off against other startups in the only game that matters: <i>trivia</i>.</p> */}

			<br />
			<h3>How it works</h3>

			<ul style={ulStyles}>
				<li>
					Assemble a team of 2-4 players from your company
				</li>
				<li>
					Meet and compete against teams from other startups
				</li>
				<li>
					It's like pub trivia! We play in multiple rounds and every team gets the same questions
				</li>
				<li>
					You'll have a chance to introduce yourselves and meet new startup friends
				</li>
				<li>
					It's free!
				</li>
			</ul>
			
			<br />
			<p>Trivia is super fun, and this is a way to both:</p>

			<ul style={ulStyles}>
				<li>Play trivia</li>
				<li>Meet people at other startups</li>
			</ul>
		</div>
	)
}

export const TriviaHowItWorks = () => {
	return (
		<div style={{maxWidth: '32rem', margin: '0 auto'}}>
			<h2>
				How it'll work
			</h2>

			<p>
				Part Jeopardy, part pub-style trivia, we built our game from the ground up and have run trivia for numerous remote-first and remote-friendly companies like Stripe, Amazon, Spotify, Rothy’s, and many YC companies.
			</p>

			<p>
				Each game will last 90 minutes, and teams will jockey for position on our leaderboard while answering questions across a range of categories: like science, pop culture, history, food, music, and art.
			</p>

			{/* <p>
				And at the end of our game, we will reveal our prizes: to the victors go the spoils! 🥇
			</p> */}
		</div>
	)
}

export const TriviaFaq = () => {
	return (
		<div style={{maxWidth: '32rem', margin: '0 auto'}}>
			<dl className='faq'>
				<dt>How much does it cost?</dt>
				<dd>We'll probably charge between $50-100 per team in the future, but this first one is totally free.</dd>

				<dt>Can my company field multiple teams?</dt> 
				<dd>Sure!</dd>

				<dt>We're based in Europe / Asia, can we play?</dt> 
				<dd>Yup! Assuming we have enough interest, we'd love to host multiple games across different time zones so everyone can play at a convenient time for them.</dd>

				<dt>What are the prizes?</dt>
				<dd>It's a secret! The prizes are just for fun &ndash; it's not a cash payout or anything 😅</dd>

				<dt>Can I ask you a question?</dt>
				<dd>Sure, drop us a line at <a href={`mailto:${contactLinks.email}`} target="_blank">{contactLinks.email}</a></dd>
			</dl>
		</div>
	)
}

const TriviaLeagueHome = () => {
	const [modalOpen, setModalOpen] = useState(false)
	
	const handleRegisterTeamClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}

	return (
		<div>
			<Header style='night' />
			<div className='container night'>
				<TriviaLeagueHeader />
				<br />	
				<TriviaIntroBlurb />			
				<TriviaHowItWorks />		
				<br />	
				<a href='/trivia-league/register' className="button">Register here</a>
			</div>
			<TriviaThemeBackground />
		</div>
		
	)
}
export default TriviaLeagueHome 