import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEvent, selectEvent, setEvent } from '../../features/events/eventSlice'
import { createAttendance, resetAttendanceStatus } from '../../features/attendance/attendanceSlice'
import Spinner from '../../components/Spinner'

const formFieldStyles = { 
	width: '100%', 
	margin: '0 0 1rem 0',
	padding: '1rem',
	fontSize: '1.5rem',
}

export const StandardForm = ({ onSubmitFunc, eventId, useAddressForm }) => {
	const dispatch = useDispatch()

	// Standard
	const firstNameValue = useSelector(state => state.attendance.newAttendance.firstName)
	const lastNameValue = useSelector(state => state.attendance.newAttendance.lastName)
	const emailValue = useSelector(state => state.attendance.newAttendance.email)
	// Address 
	const phoneValue = useSelector(state => state.attendance.newAttendance.phone)
	const streetValue = useSelector(state => state.attendance.newAttendance.street)
	const unitValue = useSelector(state => state.attendance.newAttendance.unit)
	const cityValue = useSelector(state => state.attendance.newAttendance.city)
	const stateValue = useSelector(state => state.attendance.newAttendance.state)
	const zipValue = useSelector(state => state.attendance.newAttendance.zip)
	
	// Standard
	const [firstName, setFirstName] = useState(firstNameValue);
	const [lastName, setLastName] = useState(lastNameValue);
	const [newEmail, setNewEmail] = useState(emailValue);
	// Address 
	const [phone, setPhone] = useState(phoneValue);
	const [street, setStreet] = useState(streetValue);
	const [unit, setUnit] = useState(unitValue);
	const [city, setCity] = useState(cityValue);
	const [state, setState] = useState(stateValue);
	const [zip, setZip] = useState(zipValue);
	
	const [message, setMessage] = useState('');
	const [buttonText, setButtonText] = useState('Add this person');
	
	// Standard
	const handleFirstNameChange = e => {
		setFirstName(e.target.value)
	}
	const handleLastNameChange = e => {
		setLastName(e.target.value)
	}
	const handleEmailChange = e => {
		setNewEmail(e.target.value)
	}
	// Address 
	const handlePhoneChange = e => {
		setPhone(e.target.value)
	}
	const handleStreetChange = e => {
		setStreet(e.target.value)
	}
	const handleUnitChange = e => {
		setUnit(e.target.value)
	}
	const handleCityChange = e => {
		setCity(e.target.value)
	}
	const handleStateChange = e => {
		setState(e.target.value)
	}
	const handleZipChange = e => {
		setZip(e.target.value)
	}
	
	const submitEmails = (e) => {
		e.preventDefault()
		
		dispatch(createAttendance({
			eventId: eventId,
			email: newEmail,
			first_name: firstName,
			last_name: lastName,
			phone: phone,
			street: street,
			unit: unit,
			city: city,
			state: state,
			zip: zip,
		}))
	}
	
	return (
		<div>
			<h3>Register for this event</h3>
			<p>{message}</p>
			<form onSubmit={submitEmails}>
				{/* <label>Name</label> */}
				<input onChange={handleFirstNameChange} type="text" name="first_name" placeholder="First name" style={formFieldStyles} required></input>
				<br />
				<input onChange={handleLastNameChange} type="text" name="last_name" placeholder="Last name" style={formFieldStyles} required></input>
				<br />
				{/* <label>Email</label> */}
				<input onChange={handleEmailChange} type="text" name="email" placeholder="Email" style={formFieldStyles} required></input>
				<br />

				{ useAddressForm &&
					<div>
						<input onChange={handlePhoneChange} type="text" name="phone" placeholder="Phone number" style={formFieldStyles} required></input>
						<br />
						<h4>Delivery Address</h4>
						<input onChange={handleStreetChange} type="text" name="street" placeholder="Street" style={formFieldStyles} required></input>
						<br />
						<input onChange={handleUnitChange} type="text" name="unit" placeholder="Unit" style={formFieldStyles}></input>
						<br />
						<input onChange={handleCityChange} type="text" name="city" placeholder="City" style={formFieldStyles} required></input>
						<br />
						<input onChange={handleStateChange} type="text" name="state" placeholder="State" style={formFieldStyles} required></input>
						<br />
						<input onChange={handleZipChange} type="text" name="zip" placeholder="Zip code" style={formFieldStyles} required></input>
						<br />
					</div>
				}
				<input type="submit" value={buttonText} className="button" style={{margin: '1rem auto'}}></input>
			</form>
			<p style={{lineHeight: '1.1'}}><small>You'll get a calendar invite, a notification email and a follow up email with helpful resources after the event is over.</small></p>
		</div>
	)
}

export const AddAttendeeModal = ({ eventId, setModalOpen, useAddressForm }) => {
	const dispatch = useDispatch()
	
	const loadStatus = useSelector(state => state.attendance.status)
	const newEvent = useSelector(state => state.attendance.event)

	useEffect(() => {
		if (loadStatus === 'Success') {
			dispatch(setEvent(newEvent))
		}
	}, [loadStatus, dispatch])

	const handleCloseClick = (e) => {
		setModalOpen(false);
		dispatch(resetAttendanceStatus())
	}

	const reset = (e) => {
		e.stopPropagation();
		dispatch(resetAttendanceStatus())
	}

	const stopProp = (e) => { e.stopPropagation(); }

	const states = ['Idle', 'Success', 'Error']

	const SuccessMessage = () => {
		return (
			<div>
				<h1>Success!</h1>
				<p>Your attendee  was added to the event.</p>
				<p>If you want, you can:</p>
				<a href='#' className='button' onClick={reset}>Add another</a>
			</div>
		)
	}
	
	const ErrorMessage = () => {
		return (
			<div>
				<h1>Whoops!</h1>
				<p>Uh oh, something went wrong there.</p>
				<p>Shoot us an email or, if you want:</p>
				<a href='#' className='button' onClick={reset}>Try again</a>
			</div>
		)
	}
	
	const content = { 
		Idle: <StandardForm eventId={eventId} useAddressForm={useAddressForm} />,
		Pending: (<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner /></div>),
		Success: <SuccessMessage />,
		Error: <ErrorMessage />,
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 

	return (
		<div className='modal-overlay' onClick={handleCloseClick}>
			<div className='modal' onClick={stopProp}>
				<div style={{
					width: '100%',
				}}>
					<div style={{
						position: 'absolute',
						top: '1rem',
						right: '1rem',
					}} onClick={handleCloseClick}>&times;</div>

					{content}
		
				</div>
			</div>
		</div>
	)
}

export default AddAttendeeModal