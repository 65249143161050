import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

const initialState = {
	// email: null,
  status: 'Idle',
  error: null,
  inviteRequest: {
    email: null,
    name: null,
    role_and_co: null
  }, 
}

export const fetchInviteRequest = createAsyncThunk('inviteRequests/fetchInviteRequest', async (inviteRequestId) => {
  const response = await api.get(`/invite_requests/${inviteRequestId}`)
  return response
})

export const submitNewRequest = createAsyncThunk(
  'inviteRequests/submitNew',
  async requestInfo => {
    const response = await api.post('/invite_requests', { invite_request: requestInfo })
    return response.data
  }
)

const inviteRequestSlice = createSlice({
  name: 'inviteRequests',
  initialState,
  reducers: {},
  extraReducers: {
    [submitNewRequest.fulfilled]: (state, action) => {
      state.inviteRequest = {
        ...state.inviteRequest, ...action.payload.inviteRequest
      };
      state.status = 'Success'
    }
  }
})

// export const { 
//   cityUpdated,
//   sortCities,
//   setSortBy,
//   setSortDirection,
//   setSortByOrToggleDirection,
// } = inviteRequestSlice.actions

export default inviteRequestSlice.reducer