import React from 'react'
import { contactLinks } from '../../config.js'
import EmailCaptureButton from '../salesWidgets/EmailCaptureButton'

const EmailSignupButton = () => {
	const evData = {
		noun: "newsletter signup",
		verb: "click",
	}

	return (
		<EmailCaptureButton 
		  buttonText="Subscribe" 
			evData={evData}
      className='footer-email-capture'
		/>
	)
}

export const Footer = ( data ) => {

	const styles = {}
	
	const imgUrl = data.style === 'night' ? "/Mini-Exhibitions-Logo-white.svg" : "/Mini-Exhibitions-Logo.svg"
	const textColor = ( data.style === 'night' ) ? "#eee" : "#111"

	return (
		<footer style={{
      margin: '4rem 0'
    }}>
      <ul style={styles}>
        <div className='hide-small text-left'>
          <li>Sign up to get updates about new events</li>
          <li style={{textAlign: 'left'}}>
            <EmailSignupButton />
          </li>
        </div>
        
        <div className='right'>
          <li>Legal</li>
          <li>
            <a href="#">Terms of service</a>
          </li>
          <li>
            <a href="#">Privacy policy</a>
          </li>
        </div>
        
        <div className='right'>
          <li>Contact</li>
          <li>
            <a href={`mailto:${contactLinks.email}`} target="_blank" >
              Email us 
            </a>
          </li>
          <li>
            <a href={contactLinks.calendly} target="_blank" >
              Book a call 
            </a>
          </li>
        </div>

        <div className='right'>
          <li>Social</li>
          <li>
            <a href="">LinkedIn</a>
          </li>
          <li>
            <a href="">Instagram</a>
          </li>
        </div>
      </ul>
		</footer>
	)
}

export default Footer 