import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ReactGA from 'react-ga4'
import api from '../api/api'

const initialState = {
	broadcastStatus: 'Idle',
  errors: [],
	events: [],
}

export const broadcastEvent = createAsyncThunk('userEvents/broadcast', async (data) => {
  const { 
    userId, // Subject
    noun, // Noun
    verb,
    objectId,
    objectType, // Noun
    payload,
  } = data;

  ///////////////
  // Local API //
  
  let postData = {
    user_event: {
      userId: userId, // Subject
      noun: noun,
      verb: verb,
      objectType: objectType, // Noun
      objectId: objectId,
      payload: payload,
    }
  }
  // const ownResponse = await api.post(`/user_events`, postData)
  api.post(`/user_events`, postData)
  
  //////////////////////
  // Google Analytics //

  let googArgs = {
    category: noun,
    action:   verb,
  }
 
  // console.log('googArgs: ');
  // console.log(googArgs);

  // window.ReactGA = ReactGA
  ReactGA.event(googArgs);
})

const userEventsSlice = createSlice({
  name: 'userEvents',
  initialState,
  reducers: {},
  extraReducers: {}
})

export default userEventsSlice.reducer