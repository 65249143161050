import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 

import img_bartender from '../../images/illustrations/bartender-no-bg.svg' 
import BookACallButton from '../salesWidgets/BookACallButton'

import { useSelector, useDispatch } from 'react-redux'
import { fetchUserByReferralCode, selectReferringUser } from '../../features/referrals/referralCodeSlice.js'
import { useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
import Spinner from '../../components/Spinner'

export const FullTwoColLayout = (data) => {
	return (
    <div className='two-panel-full'>
      <div className='left-col'>
        {data.leftColContent}
      </div>

      <div className='right-col behind'>
        {data.rightColContent}
      </div>
    </div>
	)
}

const BartenderImg = () => {
  return (
    <img src={img_bartender} className='bartender-illustration' />
  )
}

const LeftColContent = () => {
  return (
    <div className='inner-wrapper' style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      fontSize: '2rem',
      textAlign: 'left'
    }}>
      <p style={{marginBottom: '0'}}>
        John gave you $200 off your first event!
      </p>
      <p>
        Are you ready to throw amazing events your team will love &mdash; while you kick back and let us do all the work?
      </p>
      <div style={{fontSize: '1.5rem'}}><BookACallButton cta="Accept John's Invitation" /></div>
    </div>
  )
}

const BartenderPlay = () => {
	return (
		<div>
			<Header />
      <FullTwoColLayout leftColContent={<LeftColContent />} rightColContent={<BartenderImg />} /> 
		</div>
	)
}

export default BartenderPlay 