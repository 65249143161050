import React from 'react'

const ClayModeling = () => {
	return (
		<section name="main">
        <div className='event-description'>
          <h1 style={{lineHeight: "1.3", textAlign: 'left'}}>
            It’s squishy... 
            <br />&emsp;it’s tactile...
            <br />&emsp;&emsp;it’s the Clay Modeling 101 
            <br />&emsp;&emsp;&emsp;Mini Exhibition!
          </h1>
          <div style={{
            textAlign: 'left',
            marginTop: "3rem"
          }}>
            <dl className='horizontal' style={{textAlign: 'left'}}>
              <div>
                <dt>Duration</dt>
                <dd>60–90 minutes</dd>
              </div>
              
              <div>
                <dt>Materials</dt>
                <dd>Polymer clay, home oven</dd>
              </div>
            </dl>

            <div className="event-copy">
              <h3>
                What it's all about
              </h3> 
              <p>
                A delightfully tactile and very forgiving art form, clay modeling is relaxing and stimulating: a perfect fit for teams looking to unwind, have group conversations in a low-stress environment, and get creative.
              </p>
              
              <h3>
                Requirements
              </h3> 
              <p>This event runs for 60-90 minutes, and we ship everyone a clay modeling kit ahead of the event. Attendees will need to supply their own oven (yes, a standard oven or even a toaster oven will do just fine!) to bake their clay into a hardened, finished piece.
  </p>
              
              <h3>
                Who teaches?
              </h3> 
              <p>
                Our teacher, Laura, is a talented interdisciplinary artist who has exhibited internationally – and clay modeling is central to her artistic practice. 
              </p>
              
              <h3>Why do people love this Mini Exhibition?</h3>
              
              <ul>
                <li>Working with clay feels great! It’s satisfying and therapeutic.</li>
                <li>It’s super fun to see the creative decisions that everyone on your team makes.</li>
                <li>Everyone will have a lovely wall decoration or desk trinket at the end of the event.</li>
              </ul>
              
              <h3>
                Is it customizable?
              </h3> 
              <p>
                Darn tootin! We recommend learning to make a leaf shape, but we can base the class on other clay objects, too (like your company logo, or something with sentimental value!). 
              </p>
              <p>
                Want to know if we can accommodate? Just ask us.
              </p>
              
              <h3>
                What are the materials?
              </h3> 
              <p>
                Everyone receives their own clay – in multiple colors! – leading up to the event. <small>(We ask for a minimum 10 days of lead time to ship everyone their materials.)</small>
              </p>
              <p>
                We use a bakeable clay called Sculpey which hardens in the oven into a beautiful, durable little object. For the full experience, attendees will want access to an oven so they can keep their creation forever.
              </p>
              
              <h3>
                Well sign me up, already!
              </h3> 
              <p>Want to book a Mini Exhibition? We’d love to meet you! If you want to find out about more, you should introduce yourself to us:</p>
              
              <br></br> 
              <p>
                <a 
                  href="https://art-in-res.typeform.com/to/R3EFnDE3"
                  style={{
                    backgroundColor: '#3850ea',
                    color: "#fff",
                    padding: "12px 16px 12px 16px",
                    textAlign: "center",
                    borderRadius: "6px"
                  }}
                >
                    Tell us about you and your team
                </a>
              </p>

              <br></br> 
              <p>Hope to hear from you soon!</p> 
              
              <p>
                xo
                <br></br>
                John and John 💕
              </p>
            </div>
          </div>
        </div>
      </section>
	)
}

export default ClayModeling 