import ReactGA from 'react-ga4'
// import ReactPixel from 'react-facebook-pixel'
// import amplitude from 'amplitude-js'
// import LogRocket from 'logrocket'
// import isbot from 'isbot'
// import * as Sentry from '@sentry/browser'

// This file contains:
// 1. Set API base URL
// 2. Set API keys for 3rd party services e.g: Google Maps
// 3. Meta data defaults

const origin = document.location.origin
const host = document.location.host

const local_domain = 'lvh.me:3000'
const production_domain = 'miniexhibitions.com'
const staging_domain = 'todo.biz'

const is_local = host.includes('localhost') || host.includes('lvh')
const is_production = host.includes(production_domain) 
const is_staging = host.includes(staging_domain) 

const api_base = is_local ? `http://api.${local_domain}` : `https://api.${is_production ? production_domain : staging_domain}`
const meta_url = is_local ? `http://meta.${local_domain}` : `https://meta.${host}`

export const settings = {
  origin: origin,
  api: api_base + '/v1',
  env: is_production ? 'production' : is_staging ? 'staging' : 'development',
  meta_url,
}

export const keys = {
  google: settings.env === 'production' ? 'G-4PCKNC8SB2' : 'G-76YYVNZWEW',
  // facebook: settings.env === 'production' ? '' : '',
  // amplitude: settings.env === 'production' ? '' : '',
  // stripe: settings.env === 'production' ? '' : '',
}

export const meta = {
  title: 'Mini Exhibitions',
  separator: '-',
  description: ``,
}

const allSettings = {
  ...settings,
  keys,
  meta,
}

export default allSettings

// Setup trackers
ReactGA.initialize(keys.google)
// ReactPixel.init(keys.facebook)
// amplitude.getInstance().init(keys.amplitude, null, {
//   // optional configuration options
//   saveEvents: true,
//   includeUtm: true,
//   includeReferrer: true,
//   trackingOptions: {
//     city: true,
//     ip_address: true,
//   }
// });