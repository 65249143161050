import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

const initialState = {
  status: 'Idle',
  memberStatus: 'Idle',
  error: null,
  team: {
    id: null,
    name: null,
    coordinator: {
      full_name: null,
      email: null,
    },
    members: [], 
    coordinators: [], 
  }, 
}

export const fetchTriviaLeagueTeam = createAsyncThunk('triviaLeagueTeams/fetchTriviaLeagueTeam', async (triviaLeagueTeamId) => {
  const response = await api.get(`/teams/${triviaLeagueTeamId}`)
  return response
})

export const submitNewTeam = createAsyncThunk(
  'triviaLeagueTeams/submitNew',
  async teamInfo => {
    const response = await api.post('/teams', { team: teamInfo })
    return response.data
  }
)

export const submitNewTeamMember = createAsyncThunk(
  'triviaLeagueTeams/submitNewMember',
  async memberInfo => {
    console.log("memberInfo: ")
    console.log(memberInfo)
    const response = await api.post(`/teams/${memberInfo.team_id}/member`, { member: memberInfo })
    return response.data
  }
)

const triviaLeagueTeamSlice = createSlice({
  name: 'triviaLeagueTeams',
  initialState,
  reducers: {
    resetMemberStatus(state, action) {
      state.memberStatus = 'Idle' 
    },
  },
  extraReducers: {
    // Fetch
    [fetchTriviaLeagueTeam.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchTriviaLeagueTeam.fulfilled]: (state, action) => {
      console.log("action.payload: ")
      console.log(action.payload)
			const data = action.payload.data.team
			state.status = 'Success'
			state.team = data
    },
    [fetchTriviaLeagueTeam.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
    
    // Submit (create) 
    [submitNewTeam.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [submitNewTeam.fulfilled]: (state, action) => {
      console.log("action.payload: ")
      console.log(action.payload)
			const data = action.payload.team
			state.status = 'Success'
			state.team = data
    },
    [submitNewTeam.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
    
    // Submit new member (create) 
    [submitNewTeamMember.pending]: (state, action) => {
      state.memberStatus = 'Pending'
    },
    [submitNewTeamMember.fulfilled]: (state, action) => {
			const data = action.payload.team
			state.memberStatus = 'Success'
			state.team = data
    },
    [submitNewTeamMember.rejected]: (state, action) => {
      state.memberStatus = 'Error'
      state.error = action.error.message
    },
  }
})

export default triviaLeagueTeamSlice.reducer

export const { resetMemberStatus } = triviaLeagueTeamSlice.actions