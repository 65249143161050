import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import { openModal } from '../../features/modals/modalSlice'
import Spinner from '../../components/Spinner'
import { submitNewTeam } from '../../features/triviaLeagueTeams/triviaLeagueTeamSlice'
import { config } from '../../config'
import { 
	TriviaThemeBackground, TriviaLeagueHeader, TriviaIntroRealWayPreamble, TriviaHowItWorks, TriviaFaq, TighterPitch 
} from './TriviaLeagueHome'	

const SignUpShower = ({ clickHandler }) => {
	return(
		<div style={{margin: '3rem auto'}}>
			<a href="#" className="button" onClick={clickHandler} style={{marginBottom: '0'}}>
			 	Register a team
			</a>
		</div>
	)	
}

const formFieldStyles = { 
	width: '100%', 
	margin: '0 0 1rem 0',
	padding: '1rem',
	fontSize: '1.5rem',
}

const StandardForm = ({ onSubmitFunc }) => {
	const dispatch = useDispatch()

	// Standard
	const nameValue = useSelector(state => state.attendance.newAttendance.name)
	const companyValue = useSelector(state => state.attendance.newAttendance.company)
	const emailValue = useSelector(state => state.attendance.newAttendance.email)
	
	// Standard
	const [name, setName] = useState(nameValue);
	const [company, setCompany] = useState(companyValue);
	const [newEmail, setNewEmail] = useState(emailValue);
	
	const [message, setMessage] = useState('');
	const [buttonText, setButtonText] = useState('Begin registration');
	
	// Standard
	const handleNameChange = e => {
		setName(e.target.value)
	}
	const handleCompanyChange = e => {
		setCompany(e.target.value)
	}
	const handleEmailChange = e => {
		setNewEmail(e.target.value)
	}
	
	const submitEmails = (e) => {
		e.preventDefault()
		
		dispatch(submitNewTeam({
			name: company,
			does_trivia: true,
			coordinator: {
				full_name: name,
				email: newEmail,
			} 
		}))
	}
	
	return (
		<div>
			<h3 style={{marginBottom: '2.25rem'}}>Register your team</h3>
			<p>{message}</p>
			<form onSubmit={submitEmails}>
				<input onChange={handleNameChange} type="text" name="name" placeholder="Your name" style={formFieldStyles} required></input>
				<br />
				<input onChange={handleEmailChange} type="text" name="email" placeholder="Your email" style={formFieldStyles} required></input>
				<br />
				<input onChange={handleCompanyChange} type="text" name="company" placeholder="Your company" style={formFieldStyles} required></input>
				<br />

				<input type="submit" value={buttonText} className="button" style={{margin: '1rem auto'}}></input>
			</form>
			<p style={{lineHeight: '1.1'}}>
				{/* When you click that button, you'll get a URL you can share with other folks at your company to register to be on the same team. */}
				After you register, you'll be sent your unique team URL to share with your colleagues.
			</p>
		</div>
	)
}



const RegisterTeamModal = ({ setModalOpen }) => {
	const dispatch = useDispatch()
	
	const loadStatus = useSelector(state => state.triviaLeagueTeam.status)
	const team = useSelector(state => state.triviaLeagueTeam.team)

	const handleCloseClick = (e) => {
		setModalOpen(false);
		// dispatch(resetAttendanceStatus())
	}

	const reset = (e) => {
		e.stopPropagation();
		// dispatch(resetAttendanceStatus())
	}

	const stopProp = (e) => { e.stopPropagation(); }

	const states = ['Idle', 'Success', 'Error']
	
	const SuccessMessage = () => {
		const teamUrl = `${config.websiteRoot}/trivia-league/register/${team.id}`

		return (
			<div>
				<h1>Success!</h1>
				<p>Your team was registered.</p>
				<p>What's next?<br />Send your teammates to:</p>
				<a href={teamUrl} className='button'>Your team URL</a>
			</div>
		)
	}
	
	const ErrorMessage = () => {
		return (
			<div>
				<h1>Whoops!</h1>
				<p>Uh oh, something went wrong there.</p>
				<p>Shoot us an email or, if you want:</p>
				<a href='#' className='button' onClick={reset}>Try again</a>
			</div>
		)
	}
	
	const content = { 
		Idle: <StandardForm />,
		// Idle: <ErrorMessage />,
		Pending: (<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner /></div>),
		Success: <SuccessMessage />,
		Error: <ErrorMessage />,
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 

	return (
		<div className='modal-overlay night' onClick={handleCloseClick}>
			<div className='modal' onClick={stopProp}>
				<div style={{
					width: '100%',
				}}>
					<div className='close-x' onClick={handleCloseClick}>&times;</div>

					{content}
		
				</div>
			</div>
		</div>
	)
}

const RegisterNewTriviaTeam = () => {
	const [modalOpen, setModalOpen] = useState(false)
	
	const handleRegisterTeamClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}

	return (
		<div>
			<Header style='night' />
			<div className='container night'>
				<TriviaIntroRealWayPreamble />	
				<SignUpShower clickHandler={handleRegisterTeamClick} />
				<br />
				<TighterPitch />			

				<div style={{maxWidth: '32rem', margin: '0 auto'}}>
					<h2>
						I want to play! How do I sign up?
					</h2>

					{/* <p>
						Click the button below to register a team.
					</p> */}
					<SignUpShower clickHandler={handleRegisterTeamClick} />
					{/* <p>
						After you register, you'll be sent your unique team URL to share with your colleagues, so they can sign up to be on your team, too.
					</p> */}
					
					<br />
					<h2>
						Frequently Asked Questions
					</h2>
					<br />
				</div>
				<TriviaFaq />

				<SignUpShower clickHandler={handleRegisterTeamClick} />
				{ modalOpen ? <RegisterTeamModal setModalOpen={setModalOpen} /> : '' }
				
			</div>
			<TriviaThemeBackground />
		</div>
	)
}
export default RegisterNewTriviaTeam 