import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'
import { useDispatch } from 'react-redux'

let blankNewAttendance = {
  name: '',
  firstName: '',
  lastName: '',
  email: ''
}

const initialState = {
	status: 'Idle',
  error: null,
	user: { name: null },
	event: {
		title: 	null,
		date: 	null,
		time: 	null 
	},
  newAttendance: blankNewAttendance
}

export const fetchAttendance = createAsyncThunk('attendance/fetchAttendance', async (id) => {
  const response = await api.get(`/participations/${id}`)
	// console.log("API response: ") 
	// console.log(response) 
  return response
})

export const createAttendance = createAsyncThunk('attendance/createAttendance', async (data) => {
  const { 
    eventId, 
    teamId, 
    first_name, 
    last_name, 
    email,
    phone,
    street,
    unit,
    city,
    state,
    zip, 
  } = data;
  
  let postData = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    phone: phone,
    street: street,
    unit: unit,
    city: city,
    state: state,
    zip: zip,
    
  }

  if (teamId) {
    postData.team = {
      id: teamId
    }
  }

  const response = await api.post(`/events/${eventId}/participations`, postData)
	// console.log("API response: ") 
	// console.log(response) 
  return response
})

// export default function attendanceReducer(state = initialState, action) {
//   switch (action.type) {
//     default:
//       return state
//   }
// }

const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    resetAttendanceStatus(state, action) {
      state.status = 'Idle' 
    },
  },
  extraReducers: {
    // fetchAttendance
    [fetchAttendance.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchAttendance.fulfilled]: (state, action) => {
			const data = action.payload.data
			// console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.status = 'Success'
			state.user = data.user
			state.event = data.event
    },
    [fetchAttendance.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
    
    // createAttendance
    [createAttendance.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [createAttendance.fulfilled]: (state, action) => {
			const data = action.payload.data
      // console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.status = 'Success'
			state.event = data.event_participation.event
      state.newAttendance = blankNewAttendance
    },
    [createAttendance.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
  }
})

export default attendanceSlice.reducer

export const selectAttendance = state => state.attendance

export const { resetAttendanceStatus } = attendanceSlice.actions