import React, { useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserByReferralCode, selectReferringUser } from '../../features/referrals/referralCodeSlice.js'
import { useParams } from "react-router-dom";
import img_bartender from '../../images/illustrations/bartender-no-bg.svg' 
import { CalendlyButton, CalendlyEventListener } from '../salesWidgets/CalendlyButton'
import { FullTwoColLayout } from './BartenderPlay.js';
import Spinner from '../../components/Spinner'

const BartenderImg = (props) => {
	const cn = `bartender-illustration ${props.className}`
  return (
    <img src={img_bartender} className={cn} />
  )
}

const ReferralPageContent = ({ match }) => {
	let { referralCode } = useParams();
	const dispatch = useDispatch()
	const user = useSelector(selectReferringUser)
	const states = ['Success', 'Error']

  const loadStatus = useSelector(state => state.referralCodes.fetchStatus)

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchUserByReferralCode(referralCode))
    }
  }, [loadStatus, dispatch])
	
	const Invitation = () => {
		const referrerName = user.first_name
		const cta = `Accept ${referrerName}'s invitation` 
		return (
			<div className='inner-wrapper'>
				<BartenderImg className={'small-only'} />
				<p>
					{referrerName} gave you $200 off your first event!
				</p>
				<p>
					Are you ready to throw amazing events your team will love &mdash; while you kick back and let us do all the work?
				</p>
				<div style={{fontSize: '1.5rem'}}><CalendlyButton buttonText={cta} /></div>
				<CalendlyEventListener referralCode={referralCode} />
			</div>
		)
	}
	
	return {
 		Success: (
			<Invitation />
		),
		Pending: (
			<div>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
      <div>
        <h1>🤔<br />Hmm, we didn't find a person's referral code here.</h1>
        <p>Are you sure you spelled it correctly?</p>
      </div>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}

const ShareableReferralPage = ({ match }) => {
	return (
		<div>
			<Header />
      <FullTwoColLayout leftColContent={<ReferralPageContent match={match} />} rightColContent={<BartenderImg className={'hide-small'} />} /> 
		</div>
	)
}

export default ShareableReferralPage 