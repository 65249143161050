import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

const initialState = {
  fetchStatus: 'Idle',
  finished: false,
  error: null,
  id: null,
  activeQuestionId: null,
  questions: [],
}

export const fetchSurvey = createAsyncThunk('surveys/fetchSurvey', async (surveyId) => {
  const response = await api.get(`/surveys/${surveyId}`)
  return response
})

export const submitSurveyAnswer = createAsyncThunk('surveys/submitAnswer', async ({surveyId, answerData}) => {
  console.log("Hi from #submitSurveyAnswer")
  console.log("surveyId: ")  
  console.log(surveyId)  
  console.log("answerData: ")  
  console.log(answerData)  
  const response = await api.post(`/surveys/${surveyId}/answers`, { submitted_answer: answerData})
    return response.data
  }
)

const surveySlice = createSlice({
  name: 'surveySlice',
  initialState,
  reducers: {
    setActiveQuestionId: (state, action) => {
      state.activeQuestionId = action.payload;
    },
		selectAnswerOption: (state, action) => {
      const questionId = action.payload.question_id 
      const answerId   = action.payload.answer_option_id

      const questions = state.questions.map((q) => { 
        if (q.id == questionId) {
          const answers = q.answer_options.map((el) => {
            const selected = el.id === answerId ? true : false 
            return { ...el, selected: selected }
          })
          q.answer_options = answers
        }
        return q 
      })

      state.questions = questions
    },
    advanceActiveQuestionId: (state) => {
      const questions = state.questions;
      const questionCount = questions.length;
      const currentIndex = questions.findIndex((q) => { return q.id === state.activeQuestionId })
      let index;
      if (currentIndex >= (questionCount - 1)) {
        index = 0;
      } else {
        index = currentIndex + 1;
      }
      state.activeQuestionId = questions[index].id
    },
    recedeActiveQuestionId: (state) => {
      const questions = state.questions;
      const questionCount = questions.length;
      const currentIndex = questions.findIndex((q) => { return q.id === state.activeQuestionId })
      let index;
      if (currentIndex === 0) {
        index = questionCount - 1;
      } else {
        index = currentIndex - 1;
      }
      state.activeQuestionId = questions[index].id
    },
    markFinished(state) {
      state.finished = true 
    },
  },
  extraReducers: {
    [fetchSurvey.pending]: (state, action) => {
      state.fetchStatus = 'Pending';
    },
    [fetchSurvey.fulfilled]: (state, action) => {
      const data = action.payload.data
      state.fetchStatus = 'Success';
      state.id        = data.questionSet.id;
      state.questions = data.questionSet.questions;
      const firstQ = data.questionSet.questions[0];
      if (firstQ) {
        state.activeQuestionId = firstQ.id;
      }
    },
    [fetchSurvey.rejected]: (state, action) => {
      state.fetchStatus = 'Error';
      state.error = action.error.message;
    },
    // [submitNewRequest.fulfilled]: (state, action) => {
    //   state.inviteRequest = {
    //     ...state.inviteRequest, ...action.payload.inviteRequest
    //   };
    //   state.status = 'Success'
    // }
  }
})
export const { 
  setActiveQuestionId,
  selectAnswerOption,
  advanceActiveQuestionId,
  recedeActiveQuestionId,
  markFinished,
} = surveySlice.actions;

export default surveySlice.reducer