import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
	openModal: null 
	// openModal: 'login' 
}

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action) {
			state.openModal = action.payload 
    },
    closeModal(state, action) {
      state.openModal = null
    },
  },
})

export const { openModal, closeModal } = modalSlice.actions
export const selectOpenModal = state => state.openModal
export default modalSlice.reducer