import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

const initialState = {
	fetchStatus: 'Idle',
  error: null,
  user: {
    email: null,
    firstName: null,
    lastName: null,
    bio: null,
  }, 
}

export const fetchUserByReferralCode = createAsyncThunk('referralCodes/fetchUser', async (referralCode) => {
  const response = await api.get(`/users/by_referral_code/${referralCode}`)
  return response
})

const referralCodeSlice = createSlice({
  name: 'referralCodes',
  initialState,
  reducers: {},
  extraReducers: {
    // fetchUserByReferralCode
    [fetchUserByReferralCode.pending]: (state, action) => {
      state.fetchStatus = 'Pending'
    },
    [fetchUserByReferralCode.fulfilled]: (state, action) => {
			const data = action.payload.data
			// console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.fetchStatus = 'Success'
			state.hostInvitation = data
			state.user = data.user
    },
    [fetchUserByReferralCode.rejected]: (state, action) => {
      state.fetchStatus = 'Error'
      state.error = action.error.message
    },
  }
})

export const selectReferringUser = state => state.referralCodes.user
export default referralCodeSlice.reducer