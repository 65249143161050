import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import Footer from '../navigation/Footer.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEventArchetypes, selectEventArchetypes } from '../../features/eventArchetypes/eventArchetypesSlice'
import { WrappedGif } from '../../components/Gif.js'
import { useParams } from "react-router-dom"

const EventArchetypeThumbnail = (data) => {
  const ea = data.eventArchetype
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // padding: '1rem 1.5rem',
    padding: '0',
    border: '1px solid #cbc8be',
    borderRadius: '2px',
    backgroundColor: '#fffefc',
    position: 'relative',
    height: '20rem',
  }
  const url = `/events/${ea.slug}`
  const gifUrl = ea.gif_url ? ea.gif_url : ''

  return (
    <a href={url} className='event-type panel' style={styles}>
      {/* <div style={{height: '12rem', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}> */}
      <div style={{
        // height: '12rem', 
        backgroundImage: `url(${gifUrl})`,
        backgroundPosition: 'center', 
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
      }}>
        {/* {ea.gif_url ? <WrappedGif url={ea.gif_url} divStyles={{ width: 'auto', maxHeight: '100%' }} /> : ''} */}
      </div>
      <div style={{
        height: '10rem',
        zIndex: 10,
      }}>
        <h3 style={{
          fontSize: '1.25rem',
          margin: '0 1rem 1rem',
          padding: '0.375rem 1rem',
          backgroundColor: '#fff'
        }}>
          {ea.name}
        </h3>
        <p style={{
          fontSize: '1rem',
          margin: '0 1rem 1rem',
          padding: '0.25rem 1rem 0.5rem 1rem',
          backgroundColor: '#fff'
        }}>
          {ea.one_line_desc}
        </p>
      </div>
    </a>
  )
}

const EventArchetypeGrid = (data) => {
  return (
    <div className='grid-4-big responsive'>
      {data.eventArchetypes.map((item, i) => { return(
          <EventArchetypeThumbnail
            key={item.id}
            eventArchetype={item} 
          />
        )
      })}
    </div>
  )
}

const EventsListPage = () => {
	const dispatch = useDispatch()
	const eventArchetypes = useSelector(selectEventArchetypes)
	// const [modalOpen, setModalOpen] = useState(false)
	const states = ['Success', 'Error']
 
  console.log("eventArchetypes: ")
  console.log(eventArchetypes)

  const loadStatus = useSelector(state => state.eventArchetypes.status)
  const error = useSelector(state => state.eventArchetypes.error)

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchEventArchetypes())
    }
  }, [loadStatus, dispatch])

	return {
 		Success: (
			 <div>
        <Helmet>
          <title>Mini Exhibitions | Events</title>
        </Helmet>
				<Header />

        <div className='container wide'>
          <EventArchetypeGrid eventArchetypes={eventArchetypes} />
          <Footer />
        </div>
			</div>
		),
		Pending: (
			<div>
				<Header />
				<Helmet>
					<title>Mini Exhibitions | Events</title>
				</Helmet>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<div>
				<Header />
				<Helmet>
					<title>Mini Exhibitions | Events</title>
				</Helmet>
				<h1>Hmm, we weren't able to load events... 🤔</h1>
			</div>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}
export default EventsListPage 