import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'
// import { useDispatch } from 'react-redux'

const initialState = {
	fetchStatus: 'Idle',
	rsvpStatus: 'Idle',
  error: null,
	user: { name: null },
	event: {
		title: 	null,
		date: 	null,
		time: 	null 
	},
}

export const fetchHostInvitation = createAsyncThunk('events/fetchHostInvitation', async (id) => {
  const response = await api.get(`/events/host-invitations/${id}`)
  return response
})

export const updateHostInvitation = createAsyncThunk('events/updateHostInvitation', async (data) => {
  const { 
    invitationId, 
    rsvp, 
  } = data;
  
  let postData = {
    rsvp: rsvp,
  }
  const response = await api.put(`/events/host-invitations/${invitationId}`, postData)
	console.log("API response: ") 
	console.log(response) 
  return response
})

const hostInvitationSlice = createSlice({
  name: 'hostInvitation',
  initialState,
  reducers: {
    resetHostInvitationStatus(state, action) {
      state.fetchStatus = 'Idle' 
    },
  },
  extraReducers: {
    // fetchHostInvitation
    [fetchHostInvitation.pending]: (state, action) => {
      state.fetchStatus = 'Pending'
    },
    [fetchHostInvitation.fulfilled]: (state, action) => {
			const data = action.payload.data
			// console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.fetchStatus = 'Success'
			state.hostInvitation = data
			state.user = data.user
			state.event = data.event
    },
    [fetchHostInvitation.rejected]: (state, action) => {
      state.fetchStatus = 'Error'
      state.error = action.error.message
    },
    
    // updateHostInvitation
    [updateHostInvitation.pending]: (state, action) => {
      state.rsvpStatus = 'Pending'
    },
    [updateHostInvitation.fulfilled]: (state, action) => {
			const data = action.payload.data
      // console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.rsvpStatus = 'Success'
			state.hostInvitation = data
			// state.event = data.event_participation.event
      // state.newHostInvitation = blankNewHostInvitation
    },
    [updateHostInvitation.rejected]: (state, action) => {
      state.rsvpStatus = 'Error'
      state.error = action.error.message
    },
  }
})

export default hostInvitationSlice.reducer

export const selectHostInvitation = state => state.hostInvitation

export const { resetHostInvitationStatus } = hostInvitationSlice.actions