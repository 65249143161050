import React from 'react'
import { contactLinks } from '../../config';

export const RandomWaveEmoji = () => {
	const waveEmojis = [ '👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿' ] 
	const waveEmoji = waveEmojis[Math.floor(Math.random()*waveEmojis.length)];
	
	return (
		<span className='random-wave-emoji'>{waveEmoji}</span>
	)
}

export const Header = ( data ) => {
	const style = { style: 'day', ...data }['style']

	const styles = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: 0,
		padding: '1.66rem 2rem',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 10,
	}

	console.log('data.style: ');
	console.log(data.style);
	
	const imgUrl = data.style === 'night' ? "/Mini-Exhibitions-Logo-white.svg" : "/Mini-Exhibitions-Logo.svg"
	const textColor = ( data.style === 'night' ) ? "#eee" : "#111"

	return (
		<header style={styles}>
		  <div className="left">
		  	<a href="/">  
					<img src={imgUrl} alt="Mini Exhibitions" style={{
		  	    width: '12rem',
		  	  }}></img>
				</a>
		  </div>
			
		  <div className="right">
		    <a 
		      href={`mailto:${contactLinks.email}`} 
		      target="_blank"
		      rel="noreferrer"
		      style={{color: textColor}}
					className='say-hi'
		    >
		      Say "hi" <RandomWaveEmoji /> 
		    </a>
		  </div>
		</header>
	)
}

export default Header 