import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'
// import { useDispatch } from 'react-redux'

const initialState = {
	fetchStatus: 'Idle',
	rsvpStatus: 'Idle',
  error: null,
	user: { name: null },
	event: {
		title: 	null,
		date: 	null,
		time: 	null 
	},
}

export const fetchPresenterInvitation = createAsyncThunk('events/fetchPresenterInvitation', async (id) => {
  const response = await api.get(`/events/presenter-invitations/${id}`)
  return response
})

export const updatePresenterInvitation = createAsyncThunk('events/updatePresenterInvitation', async (data) => {
  const { 
    invitationId, 
    rsvp, 
  } = data;
  
  let postData = {
    rsvp: rsvp,
  }
  const response = await api.put(`/events/presenter-invitations/${invitationId}`, postData)
	console.log("API response: ") 
	console.log(response) 
  return response
})

const presenterInvitationSlice = createSlice({
  name: 'presenterInvitation',
  initialState,
  reducers: {
    resetPresenterInvitationStatus(state, action) {
      state.fetchStatus = 'Idle' 
    },
  },
  extraReducers: {
    // fetchPresenterInvitation
    [fetchPresenterInvitation.pending]: (state, action) => {
      state.fetchStatus = 'Pending'
    },
    [fetchPresenterInvitation.fulfilled]: (state, action) => {
			const data = action.payload.data
			// console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.fetchStatus = 'Success'
			state.presenterInvitation = data
			state.user = data.user
			state.event = data.event
    },
    [fetchPresenterInvitation.rejected]: (state, action) => {
      state.fetchStatus = 'Error'
      state.error = action.error.message
    },
    
    // updatePresenterInvitation
    [updatePresenterInvitation.pending]: (state, action) => {
      state.rsvpStatus = 'Pending'
    },
    [updatePresenterInvitation.fulfilled]: (state, action) => {
			const data = action.payload.data
      // console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.rsvpStatus = 'Success'
			state.presenterInvitation = data
			// state.event = data.event_participation.event
      // state.newPresenterInvitation = blankNewPresenterInvitation
    },
    [updatePresenterInvitation.rejected]: (state, action) => {
      state.rsvpStatus = 'Error'
      state.error = action.error.message
    },
  }
})

export default presenterInvitationSlice.reducer

export const selectPresenterInvitation = state => state.presenterInvitation

export const { resetPresenterInvitationStatus } = presenterInvitationSlice.actions