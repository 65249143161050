import img_artsy_logo from   '../../images/corpLogos/ArtsyLogo.svg' 
import img_amazon_logo from  '../../images/corpLogos/AmazonLogo.svg' 
import img_rothys_logo from  '../../images/corpLogos/RothysLogo.svg' 
import img_spotify_logo from '../../images/corpLogos/SpotifyLogo.svg' 
import img_square_logo from  '../../images/corpLogos/SquareLogo.svg' 
import img_stripe_logo from  '../../images/corpLogos/StripeLogo.svg' 
import img_svb_logo from     '../../images/corpLogos/SVBLogo.svg' 
import img_yc_logo from      '../../images/corpLogos/YCombinatorLogo.svg' 
import img_mux_logo from     '../../images/corpLogos/MuxLogo.svg' 

export const SocialProofBar = (props) => {
  const textAlign = props.textAlign ? props.textAlign : 'center'
  const styles = {
    textAlign: textAlign,
  }
  
  return(
    <div style={styles}>
      {/* <h2 style={{marginTop: '2rem'}}> */}
      <h2>
        Trusted by
      </h2>

      <div className='thirst-trap center' style={{
        display: 'flex'
      }}>
        <div className='logo-wrapper'>
          <img src={img_stripe_logo} className='stripe-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_amazon_logo} className='amazon-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_yc_logo} className='yc-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_square_logo} className='square-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_svb_logo} className='svb-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_spotify_logo} className='spotify-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_artsy_logo} className='artsy-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_rothys_logo} className='rothys-logo' />
        </div>
        
        <div className='logo-wrapper'>
          <img src={img_mux_logo} className='mux-logo' />
        </div>
      </div>	
    </div>
  )
}

export default SocialProofBar 