import React from 'react'
import Header from '../navigation/Header.js' 

const HowMembershipWorks = () => {
	return (
		<section name="main">
        <div style={{
          maxWidth: '40rem',
          margin: '2rem auto 4rem auto',
          fontSize: '21px',
          lineHeight: '1.4',
        }}>
          <h1 style={{lineHeight: "1.3"}}>How Your Mini Exhibitions Membership Works</h1>
          <div style={{
            textAlign: 'left',
            marginTop: "3rem"
          }}>
						<h2>Membership Subscription Renewal</h2>
            
            <p>Your subscription will renew on the first day of the month either every month, every other month or every quarter, depending on your subscription cadence. When your subscription renews, you will receive an event credit. </p>

					 	<table>
							<thead>
							<tr>
								<th>Monthly</th>
								<th>Bi-monthly</th>
								<th>Quarterly</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>Renews the first day of every month</td>
								<td>Renews the first day of every other month</td>
								<td>Renews the first day of the month, once per quarter</td>
							</tr>
							</tbody>
						</table>
						
						<h2>Event Credits</h2>
						<p>You can use (or not use) your event credits whenever you want. Most teams will have a preferred recurring date on which they like to schedule their events, but you are not required to adhere to that. Credits do not expire, so you can use them whenever is convenient for you. And if you need or want more, you can purchase them for the same price as you pay for your subscription.</p>
						
						<h2>Default event dates</h2>
						<p>If you want, you can set a default date for your events to occur. For example, you may want your events to always fall on the first Tuesday of the month, the last Friday, etc.
</p>
						<p>Default dates are valuable for guaranteeing that preferred time slots are available and for allowing us to help you with reminders. However, you are not required to have a default event date, and you are not required to stick to a default date if you opt to use one – you can always reschedule.</p>

						<h2>Recommendations</h2>
						<p>When a new credit becomes available on your account, we will send you suggestions for types of events you can schedule. The suggestions will be based on our understanding of your team’s preferences and personalities. These recommendations are not binding: we of course want to select whatever event is best for you and your team.</p>

						<h2>Additional credits</h2>
						<p>If you want to do more events, you can purchase additional credits for the same price as your recurring membership payment. These additional credits follow the same rules as the credits you get with your membership: you can use them or not use them at your convenience.</p>

          </div>
        </div>
      </section>
	)
}

export const HowMembershipWorksPage = () => {
	return (
		<div>
			<Header />
			<div className='container white shadow'>
				<HowMembershipWorks />
			</div>
		</div>
	)
}

export default HowMembershipWorks 