import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'
import { useDispatch } from 'react-redux'

const initialState = {
	status: 'Idle',
  error: null,
	user: { name: null },
	event: {
		title: 	null,
		date: 	null,
		time: 	null 
	},
}

export const fetchPresenting = createAsyncThunk('presenting/fetchPresenting', async (id) => {
  const response = await api.get(`/events/performances/${id}`)
  return response
})

const presentingSlice = createSlice({
  name: 'presenting',
  initialState,
  reducers: {
    resetPresentingStatus(state, action) {
      state.status = 'Idle' 
    },
  },
  extraReducers: {
    // fetchPresenting
    [fetchPresenting.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchPresenting.fulfilled]: (state, action) => {
			const data = action.payload.data
			// console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.status = 'Success'
			state.user = data.user
			state.event = data.event
    },
    [fetchPresenting.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
  }
})

export default presentingSlice.reducer

export const selectPresenting = state => state.presenting

export const { resetPresentingStatus } = presentingSlice.actions