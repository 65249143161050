// import * as css from 'styles'
// import { spin, flip } from 'styles/animations'

const size = '3em'
const size_small = '3em'
const size_medium = '3em'
const border = '2px'
const border_small = '2px'
const border_medium = '2px'
const track_color = 'transparent' //css.color.offwhitedark
// const main_color = css.color.gray

// export const Spinner = styled('div')({
//   // margin: '20px auto',
//   margin: 0,
//   fontSize: '10px',
//   position: 'relative',
//   textIndent: '-9999em',
//   // borderTop: `${border} solid ${track_color}`,
//   // borderRight: `${border} solid ${track_color}`,
//   // borderBottom: `${border} solid ${track_color}`,
//   // borderLeft: `${border} solid ${main_color}`,

//   // backgroundImage: `url(${image})`,
//   // backgroundRepeat: 'no-repeat',
//   // backgroundSize: 'cover',
//   border: `${border} solid ${track_color}`,
//   borderLeftColor: main_color,
//   borderRadius: '50%',

//   transform: 'translateZ(0)',
//   // animation: `${spin} 1.1s infinite linear`,
//   animation: `${spin} 1.3s infinite linear`,

//   // borderRadius: '50%',
//   width: size,
//   height: size,

//   '&:after': {
//     borderRadius: '50%',
//     width: size,
//     height: size,
//   },
// },propStyles({
//   small: {
//     borderTop: `${border_small} solid ${track_color}`,
//     borderRight: `${border_small} solid ${track_color}`,
//     borderBottom: `${border_small} solid ${track_color}`,
//     borderLeft: `${border_small} solid ${main_color}`,

//     width: size_small,
//     height: size_small,

//     '&:after': {
//       width: size_small,
//       height: size_small,
//     },
//   },
//   medium: {
//     borderTop: `${border_medium} solid ${track_color}`,
//     borderRight: `${border_medium} solid ${track_color}`,
//     borderBottom: `${border_medium} solid ${track_color}`,
//     borderLeft: `${border_medium} solid ${main_color}`,

//     width: size_medium,
//     height: size_medium,

//     '&:after': {
//       width: size_medium,
//       height: size_medium,
//     },
//   },
//   inline: {
//     display: 'inline-block',
//   }
// }))


export const Spinner = ({ size }) => {
	
	const track_color = 'transparent transparent transparent rgb(191, 192, 191)'
	const main_color = 'black'
	
	// const border_width = '2px'
	const { border_width, heightWidth } = {
		small: {
			border_width: '2px',
			heightWidth: '32px',
		},
		medium: {
			border_width: '4px',
			heightWidth: '48px',
		},
		large: {
			border_width: '6px',
			heightWidth: '64px',
		},
	}[ size ? size : 'medium' ]

	const styles = {
		margin: 0,
		fontSize: '10px',
		position: 'relative',
		textIndent: '-9999em',

		border: `${border_width} solid ${track_color}`,
		borderLeftColor: main_color,
		borderRadius: '50%',

		transform: 'translateZ(0)',
    animation: '1.3s linear 0s infinite normal none running spin',
    borderWidth: border_width,
    borderStyle: 'solid',
    borderColor: 'transparent transparent transparent rgb(191, 192, 191)',
    width: heightWidth,
    height: heightWidth,
	}
	return (
		<div style={styles}></div>
	)
}

export const WrappedSpinner = () => {
	return (
		<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<Spinner />
		</div>
	)
}

export default Spinner