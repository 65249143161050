import React, { useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { useSelector, useDispatch } from 'react-redux'
import { fetchHosting, selectHosting} from '../../features/attendance/hostingSlice'
import { useParams } from "react-router-dom";
import { contactLinks } from '../../config.js';

const ZoomCredentials = ({zoomAccount}) => {
	return(
		<div>
			<h4>Zoom credentials</h4>
			<dl>
				<div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
					<div>
						<dt>Username</dt>
						<dd>
							{zoomAccount.email}
						</dd>
					</div>
					<div>
						<dt>Password</dt>
						<dd>
							{zoomAccount.password}
						</dd>
					</div>
				</div>
			</dl>
		</div>
	)
}

const HostInfoPage = ({ hosting }) => {
	const teamNamesList = hosting.event.teams.attendees.map((t) => 
		t.name
	)
	const teamNames = teamNamesList.join(', ')
	
	const performerNamesList = hosting.performers.map((p) => 
		p.first_name
	)
	const performerNames = performerNamesList.join(', ')
	
	return (
		<div>
			<Header />
			<div className='container white shadow'>
				<h2>Hey {hosting.user.name}</h2>
				<p>welcome to your hosting page for:</p>
				<h1>{hosting.event.type}</h1>
				<p>with the {teamNamesList.length == 1 ? 'team' : 'teams'} at</p>
				<h1 style={{marginTop: 0}}>{teamNames}</h1>
				<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
					<div>
						<dt>Date</dt>
						<dd>{hosting.event.date}</dd>
					</div>
					
					<div>
						<dt>Time</dt>
						<dd>{hosting.event.time}</dd>
					</div>
				</dl>

				<br />
				<p>This page should include all of the information you need to host a wonderful event for the fine folks at {teamNames}.</p>

				<h2>Table of Contents</h2>
				<ul style={{textAlign: 'left', maxWidth: '20rem', margin: '0 auto'}}>
					<li>Event details and Zoom credentials</li>
					<li>Event script and presenter bio</li>
					<li>Event day checklist</li>
					<li>General hosting guidelines and tips</li>
					<li>Troubleshooting</li>
					<li>Contact us</li>
				</ul>

				<h2>Event details</h2>
				<dl>
					<div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
						<div style={{marginBottom: '2rem'}}>
							<dt>Type</dt>
							<dd>{hosting.event.type}</dd>
						</div>
						
						<div style={{marginBottom: '2rem'}}>
							<dt>Team</dt>
							<dd>{teamNames}</dd>
						</div>
					</div>
					
					<div style={{marginBottom: '2rem'}}>
						<dt>Host start time</dt>
						<dd>
							{hosting.event.date} from {hosting.event.time} - 15 minutes
						</dd>
					</div>

					<div style={{marginBottom: '2rem'}}>
						<dt>
							Event date and time 
						</dt>
						<dd>
							{hosting.event.date} from {hosting.event.time}
						</dd>
					</div>

					<div style={{marginBottom: '2rem'}}>
						<dt>
							Expected headcount
						</dt>
						<dd>
							{hosting.event.approx_headcount}
						</dd>
					</div>
				</dl>

				{hosting.zoom_account && <ZoomCredentials zoomAccount={hosting.zoom_account} />}
				
				<h2>Event script and presenter biography</h2>
				<h3>Event script</h3>
				<div style={{maxWidth: '600px', textAlign: 'left', margin: '0 auto'}}>
					<p>
						&ldquo;Hi everyone, welcome! We are Mini Exhibitions: we do virtual events for remote teams like art classes and tours, lockpicking, trivia, and mixology, just to name a few.
					</p>
					<p>
						We have the very lucky job of working with folks like you to unplug, take a little break from your busy workday, and have fun together. I hope you're all excited.
					</p>
					<p>
						Today, we have a very special event, {hosting.event.type}. {hosting.event.specific_script}
					</p>
					<p>
						We love to see everyone's faces &mdash; so please do keep your cameras on. And we love to hear from you, too &mdash; you can keep your mics on through the event to ask questions at any time, or go ahead and write in the chat, too. 
					</p>
					<p>
						How is everybody feeling?
					</p>
					<p>
						[ respond ]	
					</p>
				</div>
				<h3>{hosting.performers.length === 1 ? 'Presenter bio' : 'Presenter bios'}</h3>
				<div style={{maxWidth: '600px', textAlign: 'left', margin: '0 auto'}}>
					<p>
						Before I pass the mic off to {performerNames}, let me talk him/her/them up a little bit.
					</p>
					{hosting.performers.map((p, i) => { return(
						p.biography
					)})}
				</div>
				<h3>Kick off</h3>
				<div style={{maxWidth: '600px', textAlign: 'left', margin: '0 auto'}}>
					<p>
						Without further ado, I will pass the mic to {performerNames}.
					</p>
				</div>

				<h2>Event day checklist</h2>
				<ol style={{textAlign: 'left', maxWidth: '30rem', margin: '0 auto'}}>
					<li>
						Boot up the Zoom 15 minutes before the official event start time
						<ul>
							<li>
								Log in using the provided Zoom credentials.
							</li>
						</ul>
					</li>
					<li>Warm up with the presenter</li>
					<li>Have a chat, introduce yourselves, and get comfortable! You might want to grab a glass of water or use the bathroom, too.</li>
					<li>Check that screensharing is enabled
						<ul>
							<li>Most events require screensharing, so click the little green “^” toggle on the “Share Screen” button and ensure that “Multiple participants can share simultaneously” is selected.</li>
						</ul>
					</li>
					<li>Check that the presenter is able to share their screen.</li>
					<li>Check that the presenter's internet connection is reliable
						<ul>
							<li>If the presenter's internet is lagging (or your own is), they may want to disconnect other devices from their wireless internet connection and/or close other programs.</li>
						</ul>
					</li>
					<li>Check that presenter is able to use external cameras, if required for this event.</li>
					<li>Check that presenter has required materials on hand.</li>
				</ol>

				<h2>General hosting guidelines and tips</h2>
				<ul style={{textAlign: 'left', maxWidth: '30rem', margin: '0 auto'}}>
					<li>Encourage participation and communication
						<ul>
							<li>Ask attendees: “Any questions?”</li>
							<li>Ask attendees: “How's everyone doing?”</li>
							<li>Ask attendees: “Quick pulse check: is everyone following along?”</li>
							<li>Ask open ended questions if attendees are on the quieter side.</li>
						</ul>
					</li>
					<li>
						Keep an eye on the clock
						<ul>
							<li>Use your judgment, but you can let the presenter know when the event is about halfway through, and when the event is almost over with just 10 minutes left.
	</li>
						</ul>
					</li>
					<li>
						Read the chat
						<ul>
							<li>When attendees ask questions or leave comments in the chat, it's nice to read them aloud.</li>
							<li>Make everyone feel welcome and heard, even if they're just participating via chat.</li>
						</ul>
					</li>
					<li>
						Keep things moving
						<ul>
							<li>
							For activities with lots of attendee participation (like cooking classes and art classes), check that folks are keeping up with the presenter, and are getting help if they need it.
							</li>
						</ul>
					</li>
				</ul>

				<h2>
					Troubleshooting
				</h2>

				<ul style={{textAlign: 'left', maxWidth: '30rem', margin: '0 auto'}}>
					<li>
						Attendee doesn't have required materials
						<br />
						If a participant doesn't have the required materials:
						<ul>
							<li>Are there substitutions? You can ask the presenter to advise.</li>
							<li>Did a shipment fail to arrive? Apologize and tell them that we will ship another box to them.</li>
							<li>Are they simply unable to participate? Encourage them to hang out and enjoy &mdash; it's still fun to learn and watch.</li>
						</ul>
					</li>

					<li>
						Attendee is making loud noises
						<ul>
							<li>DM the attendee &mdash; would you mind muting your microphone?</li>
							<li>Mute the attendee (which you can do as the Zoom host)</li>
						</ul>
					</li>

					<li>
						Presenter is running late
						<ul>
							<li>Text us.</li>
							<li>Buy a little time: “We appreciate your patience &mdash; please hold tight! If you need to, now's a great time to grab a glass of water. How is everybody today?”</li>
							<li>If they no-show, apologize and let the team know that our team will fix this.</li>
						</ul>
					</li>	

					<li>
						Any other emergencies
						<ul>
							<li>Contact us, preferably via text.</li>
						</ul>
					</li>	
				</ul>	
				
				<h2 style={{marginBottom: '0.5rem'}}>
					Contact Information
				</h2>
				<p style={{margin: '0 0 1rem 0', fontSize: '80%'}}>
					The best way to contact us is different on event day vs. before event day.
				</p>

				<h3 style={{margin: '3rem 0 0.5rem 0'}}>
					Before event day
				</h3>
				<p style={{margin: '0 0 1rem 0', fontSize: '80%'}}>
					Before event day, you can email us or text us
				</p>
				<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
					<div>
						<dt>Email</dt>
						<dd>{contactLinks.email}</dd>
					</div>
					
					<div>
						<dt>John Sillings</dt>
						<dd>914-610-6523</dd>
					</div>
					
					<div>
						<dt>John Friel</dt>
						<dd>708-214-9118</dd>
					</div>
				</dl>

				<h3 style={{margin: '3rem 0 0.5rem 0'}}>
					On event day
				</h3>
				<p style={{margin: '0 0 1rem 0', fontSize: '80%'}}>
					On event day, text us. Don't email; it's too slow.
				</p>
				<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
					<div>
						<dt>John Sillings</dt>
						<dd>914-610-6523</dd>
					</div>
					<div>
						<dt>John Friel</dt>
						<dd>708-214-9118</dd>
					</div>
				</dl>
				
				<h3 style={{margin: '3rem 0 0.5rem 0'}}>
					Presenter
				</h3>
				<p style={{margin: '0 0 1rem 0', fontSize: '80%'}}>
					If you need to get in touch with the presenter
				</p>
				<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
					<div>
						<dt>Email</dt>
						<dd>TODO</dd>
					</div>
					<div>
						<dt>Phone</dt>
						<dd>TODO</dd>
					</div>
				</dl>
			</div>
		</div>
	)
}


const Host = ({ match }) => {
	// let stuff = useParams();
	// window.stuff = stuff;
	let { participationId } = useParams();
	const dispatch = useDispatch()
	const hosting = useSelector(selectHosting)
	const states = ['Success', 'Error']

	console.log("hosting: ")
	console.log(hosting)
  
  const loadStatus = useSelector(state => state.hosting.status)
  const error = useSelector(state => state.hosting.error)

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchHosting(participationId))
    }
  }, [loadStatus, dispatch])

	return {
 		Success: <HostInfoPage hosting={hosting} />,
		Pending: (
			<div>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<h1>Hmm, we didn't find hosting information for you here 🤔</h1>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}
export default Host 