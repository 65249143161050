import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAttendance, selectAttendance } from '../features/attendance/attendanceSlice'
import { useParams } from "react-router-dom";

const Attendee = ({ match }) => {
	// let stuff = useParams();
	// window.stuff = stuff;
	let { participationId } = useParams();
	const dispatch = useDispatch()
	const attendance = useSelector(selectAttendance)
	const states = ['Success', 'Error']

	console.log("attendance: ")
	console.log(attendance)
  
  const loadStatus = useSelector(state => state.attendance.status)
  const error = useSelector(state => state.attendance.error)

	useEffect(() => {
    if (loadStatus === 'idle') {
      dispatch(fetchAttendance(participationId))
    }
  }, [loadStatus, dispatch])

	return {
 		Success: (
			<div style={{marginTop: '4rem'}}>
				<h1>👋 Hi {attendance.user.name}</h1>
				<p>This is your personal page for the event:</p>
				<h2>{attendance.event.title}</h2>
				<p>What can you do here?</p>
				<p>You can:</p>
				<div className="grid-3">
					<div>Add this event to your calendar</div>
					<div>Record your RSVP</div>
					<div>Invite someone else from your team</div>
				</div>
				<br />
				<br />
				<br />
				<h2>
					Event info
				</h2>
				<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
					<div>
						<dt>Date</dt>
						<dd>{attendance.event.date}</dd>
					</div>
					
					<div>
						<dt>Time</dt>
						<dd>{attendance.event.time}</dd>
					</div>
				</dl>
				<br />
				<h3>Description</h3>
				<p>
					You've probably been to a museum before, but have you ever been inside an artist's studio? Pour yourself a glass of wine (or the beverage of your choice) and get ready for a glimpse of the real art world. Mini Exhibitions are virtual studio visits – an intimate cultural experience with two working artists.
				</p>
				<p>
					Two of our artists will share their stories, their studios, and the legacies that they are building with their work. You'll get a behind-the-scenes look at how they make their art and navigate the art world. And you'll get to ask (almost) any question, as is customary of a studio visit, a storied and privileged tradition in fine art.
				</p>
				<p>
					We have two amazing artists booked for you! You can read more about them below (or don't, if you want it to be a surprise!) :)
				</p>
				<h2>
					RSVPs
				</h2>
			</div>
		),
		Pending: (
			<div>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<h1>Hmm, we didn't find an event RSVP for you here 🤔</h1>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}
export default Attendee 