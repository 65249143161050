import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'
import { useDispatch } from 'react-redux'

const initialState = {
	status: 'Idle',
  error: null,
	user: { name: null },
	event: {
		title: 	null,
		date: 	null,
		time: 	null 
	},
  performers: [],
  zoom_account: {},
}

export const fetchHosting = createAsyncThunk('hosting/fetchHosting', async (id) => {
  const response = await api.get(`/events/hostings/${id}`)
  return response
})

const hostingSlice = createSlice({
  name: 'hosting',
  initialState,
  reducers: {
    resetHostingStatus(state, action) {
      state.status = 'Idle' 
    },
  },
  extraReducers: {
    // fetchHosting
    [fetchHosting.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchHosting.fulfilled]: (state, action) => {
			const data = action.payload.data
			// console.log("succeeded! action.payload.data: ")
      // console.log(data)
			state.status = 'Success'
			state.user = data.user
			state.event = data.event
			state.performers = data.performers
			state.zoom_account = data.zoom_account
    },
    [fetchHosting.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
  }
})

export default hostingSlice.reducer

export const selectHosting = state => state.hosting

export const { resetHostingStatus } = hostingSlice.actions