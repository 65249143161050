import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { useSelector, useDispatch } from 'react-redux'
import { fetchHostInvitation, selectHostInvitation, updateHostInvitation } from '../../features/events/hostInvitationSlice'
import { useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
// import { openModal } from '../features/modals/modalSlice'
import Spinner from '../../components/Spinner'

const StartResponseButton = ({ clickHandler }) => {
	return(
		<div>
			<a href="#" className="button" onClick={clickHandler} style={{marginBottom: '0'}}>
				Respond to invitation
			</a>
			<br />
			<p style={{fontSize: '0.875rem'}}>(Tell us whether or not you can be the host)</p>
		</div>
	)	
}

const LabeledValue = ({ label, value }) => {
	return (
		<div style={{margin: '1rem 1rem 2rem'}}>
			<dt>{label}</dt>
			<dd>{value}</dd>
		</div>
	)	
}

const ResponseModal = ({ invitationId, hostInvitation, setModalOpen }) => {
	const dispatch = useDispatch()
	
	const loadStatus = useSelector(state => state.hostInvitation.rsvpStatus)

	const handleCloseClick = (e) => {
		setModalOpen(false);
	}

	const handleYesClick = (e) => {
		e.preventDefault()

		dispatch(updateHostInvitation({
			invitationId: invitationId,
			rsvp: true,
		}))
	}
	const handleNoClick = (e) => {
		e.preventDefault()
		
		dispatch(updateHostInvitation({
			invitationId: invitationId,
			rsvp: false,
		}))
	}

	const stopProp = (e) => { e.stopPropagation(); }

	const states = ['Idle', 'Success', 'Error']

	const YouGotItMessage = () => {
		return (
			<div>
				<h1>Success!</h1>
				<p>You got it! The event is yours!</p>
				<p>As we get it, the full information for the event will be here:</p>
				<a href={hostInvitation.hostInvitation.host_url} className="button">
					Your host page for this event
				</a>
			</div>
		)
	}

	const YouDeclinedMessage = () => {
		return (
			<div>
				<h1>Roger that!</h1>
				<p>You declined to host this event.</p>
				<p>Thanks for letting us know! We'll keep sending you more invitations in the future.</p>
			</div>
		)
	}
	// const SuccessMessage = () => {
	// 	return (
	// 		<div>
	// 			<h1>Success!</h1>
	// 			<p>You got it! The event is yours!</p>
	// 			<p>As we get it, the full information for the event will be here:</p>
	// 			<a href='#' className="button">
	// 				Your host page for this event
	// 			</a>
	// 		</div>
	// 	)
	// }
	
	const ErrorMessage = () => {
		return (
			<div>
				<h1>Whoops!</h1>
				<p>Uh oh, something went wrong there.</p>
				<p>Shoot us an email!</p>
			</div>
		)
	}
	
	const RsvpPrompt = () => {
		return (
			<div>
				<h2 style={{marginTop: '0'}}>
					Your response
				</h2>
				<p>
					Are you available?
					<br />
					Do you want to host this event?
				</p>
				<br />
				
				<div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', maxWidth: '400px', margin: '0 auto'}}>
					<div>
						<a onClick={handleYesClick} href="#" className="button" style={{marginBottom: '0'}}>
							Yes!
						</a>
					</div>
					
					<div>
						<a onClick={handleNoClick} href="#" className="button" style={{marginBottom: '0'}}>
							Nope
						</a>
					</div>
				</div>
			</div>
		)
	}
	
	
	const content = { 
		Idle: (<RsvpPrompt />),
		// Idle: <StandardForm eventId={eventId} useAddressForm={useAddressForm} />,
		// Idle: <ErrorMessage />,
		Pending: (<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner /></div>),
		Success: (hostInvitation.user_is_host ? <YouGotItMessage /> : <YouDeclinedMessage /> ),
		Error: <ErrorMessage />,
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 

	return (
		<div className='modal-overlay' onClick={handleCloseClick}>
			<div className='modal' onClick={stopProp}>
				<div className='modal-inner-100'>
					<div className='modal-close-button' onClick={handleCloseClick}>&times;</div>

					{content}
		
				</div>
			</div>
		</div>
	)
}

const HostInvitationContent = ({ match }) => {
	// let stuff = useParams();
	// window.stuff = stuff;
	let { invitationId } = useParams();
	const dispatch = useDispatch()
	const hostInvitation = useSelector(selectHostInvitation)
	const [modalOpen, setModalOpen] = useState(false)
	const states = ['Success', 'Error']

	console.log("hostInvitation: ")
	console.log(hostInvitation)
  
  const loadStatus = useSelector(state => state.hostInvitation.fetchStatus)
  const error = useSelector(state => state.hostInvitation.error)
	
	const handleResponseButtonClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchHostInvitation(invitationId))
    }
  }, [loadStatus, dispatch])

	// const userIsHost = hostInvitation.user_is_host
	
	const Invitation = () => {
		return (
			<div>
				<h1>👋 Hi {hostInvitation.user.first_name}</h1>
				{ hostInvitation.hostInvitation.still_open ? 
						<p>We'd like to invite you to host an event for us!</p>
					: 
						<p>Sorry, someone already registered to host this event!</p>
				}
				
				<h2>
					Event info
				</h2>
				<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
					<LabeledValue label={'Date'} value={hostInvitation.event.date} />
					<LabeledValue label={'Time'} value={hostInvitation.event.time} />
					<LabeledValue label={'Duration'} value={hostInvitation.event.duration} />
					<div>&nbsp;</div>
					{ 
						( hostInvitation.hostInvitation || {} ).hourly_rate &&
							<LabeledValue label={'Hourly rate'} value={<NumberFormat value={hostInvitation.hostInvitation.hourly_rate} displayType={'text'} prefix={'$'} />} /> 
					}
				</dl>
				
				{ hostInvitation.hostInvitation.still_open ? 
						<StartResponseButton clickHandler={handleResponseButtonClick} /> 
					: 
						<p>We'll keep you in mind next time!</p>
				}
				
				
				{ modalOpen ? 
						<ResponseModal invitationId={invitationId} hostInvitation={hostInvitation} setModalOpen={setModalOpen} /> : 
						'' 
				}
			</div>
		)
	}
	
	const InvitationAccepted = () => {
		return (
			<div className='container' style={{maxWidth: '800px', margin: '4rem auto', fontSize: '21px', lineHeight: '1.4'}}>
				<h1>👋 Hi {hostInvitation.user.first_name}</h1>
				<p>Thanks for registering to host this event for us!</p>
				<p>All the info you need is at</p>
				<a href={hostInvitation.hostInvitation.host_url} className="button">
					Your host page for this event
				</a>
			</div>
		)
	}

	return {
 		Success: (
			( hostInvitation.hostInvitation || {} ).user_is_host ? <InvitationAccepted /> : <Invitation />
		),
		Pending: (
			<div>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<h1>Hmm, we didn't find an event invitation for you here 🤔</h1>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}

const HostInvitation = ({ match }) => {
	return (
		<div>
			<Header />
			<div className='container white shadow'>
				<HostInvitationContent match={match} />
			</div>
		</div>
	)
}

export default HostInvitation 