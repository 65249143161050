import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams, 
  // Link
} from "react-router-dom";

import allSettings from "./settings.js";
import ReactGA from 'react-ga4'

import Header from './app/navigation/Header.js'
// import MaybeModal from './app/MaybeModal.js'
import LandingRedux from './app/landingPages/LandingRedux.js'
import LandingJuly2022 from './app/landingPages/LandingJuly2022'
import { HowMembershipWorksPage } from './app/salesPages/HowMembershipWorks'
import EventsListPage from './app/browsing/EventsListPage'
import IndividualEventArchetypePage from './app/browsing/IndividualEventArchetypePage'
import OldEventsListPage from './app/eventDescriptions/OldEventsListPage'
import ClayModeling from './app/eventDescriptions/ClayModeling'
import ShareableEventPage from './app/interactiveEventPages/ShareableEventPage'
import ShareableProgramPage from './app/interactiveEventPages/ShareableProgramPage'
import Attendee from './app/Attendee'
import Host from './app/interactiveEventPages/Host'
import HostInvitation from "./app/interactiveEventPages/HostInvitation.js";
import Presenter from './app/interactiveEventPages/Presenter'
import PresenterInvitation from "./app/interactiveEventPages/PresenterInvitation.js";
import SurveyPage from "./app/surveys/SurveyPage.js";

// SALES PAGES
import ShareableNewLeadOverviewPage from "./app/salesPages/ShareableNewLeadOverviewPage";
import ShareableReferralPage from './app/salesPages/ShareableReferralPage'
import BartenderPlay from './app/salesPages/BartenderPlay'

// Trivia league
import TriviaLeagueHome from "./app/triviaLeague/TriviaLeagueHome";
import RegisterNewTriviaTeam from "./app/triviaLeague/RegisterNewTriviaTeam";
import TriviaTeamShareableSignUpForm from "./app/triviaLeague/TriviaTeamShareableSignUpForm";


function App() {
  ReactGA.send({ hitType: "pageview", page: document.location.pathname });

  return (
    <Router>
      <div className="App">
        {/* <Header /> */}

        <Switch>
          <Route path="/r/:referralCode">
            <ShareableReferralPage />
          </Route>
          <Route path="/events/:archetypeId">
            <IndividualEventArchetypePage />
          </Route>
          <Route path="/events">
            <EventsListPage />
          </Route>
          
          {/* APP */}
          <Route path="/event/:eventId">
            <ShareableEventPage />
          </Route>
          <Route path="/program/:programId"> 
            <ShareableProgramPage />
          </Route>
          <Route path="/rsvp/:participationId">
            <Attendee />
          </Route>
          <Route path="/host/:participationId"> 
            <Host />
          </Route>
          <Route path="/performance/:participationId"> 
            <Presenter />
          </Route>
          <Route path="/performance-invitation/:invitationId"> 
            <PresenterInvitation />
          </Route>
          <Route path="/presenter-invitation/:invitationId"> 
            <PresenterInvitation />
          </Route>
          <Route path="/host-invitation/:invitationId"> 
            <HostInvitation />
          </Route>

          {/* SALES PAGES */}

          <Route path="/welcome/:teamId"> 
            <ShareableNewLeadOverviewPage />
          </Route>

          {/* <Route path="/yc">
            <ForYCombinator />
          </Route> */}

          {/* This got sent out to Mux */}
          <Route path="/claymodeling">
            <ClayModeling />
          </Route> 
          
          {/* <Route path="/remote-events">
            <EventsListPage showTitle={true} />
          </Route>
          <Route path="/remote-events/clay-modeling-class">
            <ClayModeling />
          </Route> */}
          
          <Route path="/how-membership-works">
            <HowMembershipWorksPage />
          </Route>
          
          {/* SURVEYS */}
          
          <Route path="/survey/:surveyId">
            <SurveyPage />
          </Route>

          {/* TRIVIA LEAGUE */}

          <Route path="/trivia-league/register/:teamId"> 
            <TriviaTeamShareableSignUpForm />
          </Route>
          <Route path="/trivia-league/register"> 
            <RegisterNewTriviaTeam />
          </Route>
          <Route path="/trivia-league"> 
            <TriviaLeagueHome />
          </Route>
          
          <Route path="/"> 
            <LandingJuly2022 />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
