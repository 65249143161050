import React, { useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { useSelector, useDispatch } from 'react-redux'
import { fetchPresenting, selectPresenting} from '../../features/attendance/presentingSlice'
import { useParams } from "react-router-dom";

const PresenterInfoPage = ({ presenting }) => {
	const teamNamesList = presenting.event.teams.attendees.map((t) => 
		t.name
	)
	console.log('teamNamesList')
	console.log(teamNamesList)
	const teamNames = teamNamesList.join(', ')
	console.log('teamNames')
	console.log(teamNames)
	
	return (
		<div>
			<Header />
			<div className='container white shadow'>
				<h2>Hey {presenting.user.name}</h2>
				<p>welcome to your presenting page for:</p>
				<h1>{presenting.event.type}</h1>
				<p>with the {teamNamesList.length == 1 ? 'team' : 'teams'} at</p>
				<h1 style={{marginTop: 0}}>{teamNames}</h1>
				<dl style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
					<div>
						<dt>Date</dt>
						<dd>{presenting.event.date}</dd>
					</div>
					
					<div>
						<dt>Time</dt>
						<dd>{presenting.event.time}</dd>
					</div>
				</dl>

				<br />
				<p>This page should include all of the information you need to host a wonderful event for the fine folks at {teamNames}.</p>
			</div>
		</div>
	)
}


const Presenter = ({ match }) => {
	// let stuff = useParams();
	// window.stuff = stuff;
	let { participationId } = useParams();
	const dispatch = useDispatch()
	const presenting = useSelector(selectPresenting)
	const states = ['Success', 'Error']

	console.log("presenting: ")
	console.log(presenting)
  
  const loadStatus = useSelector(state => state.presenting.status)
  const error = useSelector(state => state.presenting.error)

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchPresenting(participationId))
    }
  }, [loadStatus, dispatch])

	return {
 		Success: <PresenterInfoPage presenting={presenting} />,
		Pending: (
			<div>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<h1>Hmm, we didn't find presenter information for you here 🤔</h1>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}
export default Presenter 