// This file contains:
// 1. Set API base URL
// 2. Set API keys for 3rd party services e.g: Google Maps
// 3. Meta data defaults

const origin = document.location.origin
const host = document.location.host

const isProduction = process.env.NODE_ENV === 'production'
const isLocal = !isProduction 

const websiteRoot = isLocal ? `http://lvh.me:3001` : `https://miniexhibitions.com`
const apiRoot = isLocal ? `http://api.lvh.me:3000/v1` : `https://api.miniexhibitions.com/v1`

export const contactLinks = {
  email: 'everyone@miniexhibitions.com',
  calendly: 'https://calendly.com/miniexhibitions/chat',
  linkedIn: 'https://www.linkedin.com/company/miniexhibitions/',
  instagram: 'https://www.instagram.com/miniexhibitions/',
}

export const config = {
  origin: origin,
  apiRoot: apiRoot,
  websiteRoot: websiteRoot,
  env: isProduction ? 'production' : 'development',
}
