export const WrappedGif = ({url, divStyles, gifStyles }) => {
	const defaultDivStyles = {
		margin: '1rem auto',
		width: '20rem',
	}	
  // const dStyles = { ...divStyles, defaultDivStyles }
  const dStyles = divStyles ? { ...divStyles, defaultDivStyles } : defaultDivStyles
	
	const defaultGifStyles = {
		maxWidth: '100%',
	}
  const gStyles = gifStyles ? { ...gifStyles, defaultGifStyles } : defaultGifStyles

	return(
		<div style={dStyles}>
			<img src={url} style={gStyles} />
		</div>
	)
}