import React from 'react'


const PricingTable = (data) => {
	const defaultTiers = [
		{
			name: 'Monthly',
			price: '$1,000',
			label: 'Membership', 
		}, {
			name: 'Every-other month',
			price: '$1,200',
			label: 'Membership', 
		}, {
			name: 'Every quarter',
			price: '$1,400',
			label: 'Membership' 
		}, {
			name: 'One-off event',
			price: '$2,000',
			label: '' 
		}
	]

	let tiers;
	if (data.pricing) {
		tiers = data.pricing 
	} else {
		tiers = defaultTiers
	}
	
	return (
		<div>
			<table style={{
				width: '100%',
				fontSize: '1.5rem',
			}}>
				<thead>
					<tr>
						<th>Suggested cadence</th>
						<th>Price per event</th>
					</tr>
				</thead>
				
				<tbody>
					{tiers.map((item, i) => { return(
							<tr key={i}>
								<td>
									<div>
										{item.name}
									</div>
									<div style={{
										fontSize: '1rem',
										margin: '0.5rem 0',
										opacity: '0.7'
										}}>
										{item.label}
									</div>
								</td>
								<td>{item.price}</td>
							</tr> 
						)
					})}
				</tbody>
			</table>
			
		</div>
		
	)
}
export default PricingTable 