import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEvent, selectEvent, setEvent } from '../../features/events/eventSlice'
import { createAttendance, resetAttendanceStatus } from '../../features/attendance/attendanceSlice'
import Spinner from '../../components/Spinner'

const formFieldStyles = { 
	width: '100%', 
	margin: '0 0 1rem 0',
	padding: '1rem',
	fontSize: '1.5rem',
}

export const StandardForm = ({ onSubmitFunc, team, eventId }) => {
	const dispatch = useDispatch()

	// Standard
	const firstNameValue = useSelector(state => state.attendance.newAttendance.firstName)
	const lastNameValue = useSelector(state => state.attendance.newAttendance.lastName)
	const emailValue = useSelector(state => state.attendance.newAttendance.email)
	
	// Standard
	const [firstName, setFirstName] = useState(firstNameValue);
	const [lastName, setLastName] = useState(lastNameValue);
	const [newEmail, setNewEmail] = useState(emailValue);
	
	const [message, setMessage] = useState('');
	const [buttonText, setButtonText] = useState('Join team');
	
	// Standard
	const handleFirstNameChange = e => {
		setFirstName(e.target.value)
	}
	const handleLastNameChange = e => {
		setLastName(e.target.value)
	}
	const handleEmailChange = e => {
		setNewEmail(e.target.value)
	}
	
	const submitTeamMember = (e) => {
		e.preventDefault()
		
		dispatch(createAttendance({
			eventId: eventId,
			teamId: team.id,
			email: newEmail,
			first_name: firstName,
			last_name: lastName,
		}))
	}
	
	return (
		<div>
			<h3>Register to join {team.name}</h3>
			<p>{message}</p>
			<form onSubmit={submitTeamMember}>
				{/* <label>Name</label> */}
				<input onChange={handleFirstNameChange} type="text" name="first_name" placeholder="First name" style={formFieldStyles} required></input>
				<br />
				<input onChange={handleLastNameChange} type="text" name="last_name" placeholder="Last name" style={formFieldStyles} required></input>
				<br />
				{/* <label>Email</label> */}
				<input onChange={handleEmailChange} type="text" name="email" placeholder="Email" style={formFieldStyles} required></input>
				<br />

				<input type="submit" value={buttonText} className="button" style={{margin: '1rem auto'}}></input>
			</form>
		</div>
	)
}

export const JoinTeamModal = ({ team, eventId, setModalOpen, useAddressForm }) => {
	const dispatch = useDispatch()
	
	const loadStatus = useSelector(state => state.attendance.status)
	const newEvent = useSelector(state => state.attendance.event)

	useEffect(() => {
		if (loadStatus === 'Success') {
			dispatch(setEvent(newEvent))
		}
	}, [loadStatus, dispatch])

	const handleCloseClick = (e) => {
		setModalOpen(false);
		dispatch(resetAttendanceStatus())
	}

	const reset = (e) => {
		e.stopPropagation();
		dispatch(resetAttendanceStatus())
	}

	const stopProp = (e) => { e.stopPropagation(); }

	const states = ['Idle', 'Success', 'Error']

	const SuccessMessage = () => {
		return (
			<div>
				<h1>Success!</h1>
				<p>You joined the team.</p>
				<p>If you want, you can:</p>
				<a href='#' className='button' onClick={reset}>Add someone else, too</a>
			</div>
		)
	}
	
	const ErrorMessage = () => {
		return (
			<div>
				<h1>Whoops!</h1>
				<p>Uh oh, something went wrong there.</p>
				<p>Shoot us an email or, if you want:</p>
				<a href='#' className='button' onClick={reset}>Try again</a>
			</div>
		)
	}
	
	const content = { 
		Idle: <StandardForm team={team} eventId={eventId} useAddressForm={useAddressForm} />,
		Pending: (<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner /></div>),
		Success: <SuccessMessage />,
		Error: <ErrorMessage />,
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 

	return (
		<div className='modal-overlay' onClick={handleCloseClick}>
			<div className='modal' onClick={stopProp}>
				<div style={{
					width: '100%',
				}}>
					<div style={{
						position: 'absolute',
						top: '1rem',
						right: '1rem',
					}} onClick={handleCloseClick}>&times;</div>

					{content}
		
				</div>
			</div>
		</div>
	)
}

export default JoinTeamModal