import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import Footer from '../navigation/Footer.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEventArchetype, selectEventArchetype } from '../../features/eventArchetypes/eventArchetypesSlice'
import { useParams } from "react-router-dom"
import { WrappedGif } from '../../components/Gif.js'

const OptionalSection = (data) => {
	const render = typeof(data.body) !== 'undefined' 
	return(
		<>
			{render &&
				<div>
					{data.title && <h2>{data.title}</h2>}
					<p>{data.body}</p>
				</div> 
			}
		</>
	)
}

const IndivdualEventArchetypePage  = () => {
	const { archetypeId } = useParams();
	const dispatch = useDispatch()
	const eventArchetypeData = useSelector(selectEventArchetype)
	const [modalOpen, setModalOpen] = useState(false)
	const states = ['Success', 'Error']
  
  const loadStatus = useSelector(state => state.eventArchetypes.status)
  const error = useSelector(state => state.eventArchetypes.error)

	const gifUrl = eventArchetypeData.gif_url

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchEventArchetype(archetypeId))
    }
  }, [loadStatus, dispatch])

	const handleAddAttendeeClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}

	return {
 		Success: (
			 <div>
				<Header />
				<div className='container white border'>
					<Helmet>
						<title>{eventArchetypeData.name} | Mini Exhibitions</title>
					</Helmet>
					
					<h1>{eventArchetypeData.name}</h1>
					
					<OptionalSection body={ eventArchetypeData.one_line_desc } />					
					
					<dl style={{display: 'flex', justifyContent: 'center'}}>
						<div>
							<dt>Duration</dt>
							<dd>60-90 minutes</dd>
						</div>
					</dl>
					<br />
					
					{gifUrl ? <WrappedGif url={gifUrl} /> : ''}

					<OptionalSection title="What it's all about" body={ eventArchetypeData.brief_desc } />					
					<OptionalSection title="Why do people love this Mini Exhibition?" body={ eventArchetypeData.why_ppl_love } />					
					<OptionalSection title="What are the materials?" body={ eventArchetypeData.materials_desc } />					

					<br />
					
					{/* { modalOpen ? <AddAttendeeModal eventId={eventId} setModalOpen={setModalOpen} useAddressForm={eventData.event.requires_addresses} /> : '' } */}
				</div>
			</div>
		),
		Pending: (
			<div>
				<Header />
				<Helmet>
					<title>Mini Exhibitions | Loading...</title>
				</Helmet>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<div>
				<Header />
				<Helmet>
					<title>Event not found | Mini Exhibitions</title>
				</Helmet>
				<h1>Hmm, we didn't find an event here 🤔</h1>
			</div>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}

export default IndivdualEventArchetypePage 