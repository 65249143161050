import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  submitNewRequest,
} from '../../features/inviteRequests/inviteRequestSlice'

// TODO update with new link
const calendlyUrl = 'https://calendly.com/miniexhibitions/chat'

const BookACallButton = (props) => {
	const cta = props.cta ? props.cta : "Book a call"
	return(
		<a 
			href={calendlyUrl}
			target='_blank'
			className='button'
		>
		  {cta}	
		</a>
	)
}

export default BookACallButton