const AttendeeList = (props) => {
	if (props.list.length > 0) {
		
		const sortedItems = props.list.slice().sort((a, b) => {
			let aVal = `${a.last_name}${a.first_name}${a.email}`
			let bVal = `${b.last_name}${b.first_name}${b.email}`

			return aVal - bVal
		})
		const listItems = sortedItems.map((user, i) =>
		  <li style={{listStyle: 'none'}} key={user.id}>{user.name}</li>
		);
		
		return (
			<dl>
				<dt>{props.name}</dt>
				<dd style={{fontSize: '1.5rem'}}>
					<ul style={{paddingLeft: 0}}>
						{listItems}
					</ul>
				</dd>
				<br />
			</dl>
		) 
	} else {
		return (
			<dl>
				<dt>{props.name}</dt>
				<dd>TBD</dd>
				<br />
			</dl>
		) 
	}
}

export default AttendeeList 