import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

// Sentry - error reporting
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Styles
// import './styles/0_normalize.css';
import './styles/1_colors.scss';
import './styles/2_typography.scss';
import './styles/3_layout.scss';
import './styles/4_information.scss';
import './styles/5_forms.scss';
import './styles/6_modals.scss';

import './styles/index.scss';
import './styles/App.scss';
import './styles/Navigation.scss';
import './styles/landingPages/LandingRedux.scss';
import './styles/landingPages/LandingJuly2022.scss';
import './styles/landingPages/SocialProof.scss';
import './styles/landingPages/SalesPages.scss';
import './styles/landingPages/TwoPanelLanding.scss';
import './styles/landingPages/CallToActionWidgets.scss';

import App from './App';
import configureStore from './configureStore'

// What is this?
import reportWebVitals from './reportWebVitals';

// Fontskis
import './fonts/Romana/RomanaW03-Rom.woff'
import './fonts/Romana/RomanaW03-Bd.woff'

if (process.env.NODE_ENV === 'production') {
  console.log("Initializing Sentry...");
  Sentry.init({
    dsn: "https://d0e41e5fca1c4787860179026bb789cf@o1044767.ingest.sentry.io/6020018",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const store = configureStore()

const renderApp = () =>
  ReactDOM.render(
    // </React.StrictMode>,
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp)
}

renderApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
