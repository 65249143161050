import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api/api'

const initialState = {
	status: 'Idle',
  error: null,
	program: {
		title:      	null,
		date: 	      null,
    description:  null,
    team:      {},
    timeSlots: [],
    events:    [],
	}
}

export const fetchProgram = createAsyncThunk('programs/fetchProgram', async (programId) => {
  const response = await api.get(`/programs/${programId}`)
	// console.log("API response: ") 
	// console.log(response) 
  return response
})

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setProgram(state, action) {
      state.program = action.payload
    },
  },
  extraReducers: {
    [fetchProgram.pending]: (state, action) => {
      state.status = 'Pending'
    },
    [fetchProgram.fulfilled]: (state, action) => {
			const data = action.payload.data
			state.status = 'Success'
			state.program = data.program
    },
    [fetchProgram.rejected]: (state, action) => {
      state.status = 'Error'
      state.error = action.error.message
    },
  }
})

export const selectProgram = state => state.program
// export const setProgram = state => state.program

export const { setProgram } = programSlice.actions
export default programSlice.reducer