import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTeam, selectTeam, setTeam } from '../../features/teams/teamSlice'
import { useParams } from "react-router-dom"

import BookACallButton from '../salesWidgets/BookACallButton'
import PricingTable from './PricingTable'
import Faq from './Faq'
import EventsList from '../eventDescriptions/EventsList'
import EventsListTerse from '../eventDescriptions/EventsListTerse'

import Spinner from '../../components/Spinner'
import { contactLinks } from '../../config.js'


const calendlyUrl = 'https://calendly.com/miniexhibitions/chat'

const Widget = (data) => {
	return(
		<div>

		</div>
	)
}

const Intro = (data) => {
	console.log("`data.team` inside Intro:")
	console.log(data.team)
	return(
		<div style={{maxWidth: '24rem', margin: '0 auto'}}>
			<h1>👋&ensp;Hi team {data.team.name}!</h1>
			<br />
			<p>
				We're <strong>Mini Exhibitions</strong>. 
			</p>
			<p>
				We put on special & unforgettable virtual events for remote teams. 
			</p>
		</div>
	)
}

const ContentsOne = (data) => {
	return(
		<div>
			<p>
				This page includes some <a href="#eventIdeas">event ideas</a> that we think would be a good fit for {data.team.name}, <a href="#pricing">our pricing</a>, and <a href="#faq">our FAQ</a> &ndash; and if you'd like to chat with us and try out an event, you can always grab time on our <a href={calendlyUrl}>calendar</a> when it's convenient for you.
			</p>
		</div>
	)
}

const ContentsTwo = (data) => {
	return(
		<div style={{textAlign: 'left'}}>
			<h2>
				This page has:
			</h2>
			<ol>
				<li>Some event ideas that we think would be a good fit for {data.team.name}</li>
				<li>Our pricing</li>
				<li>Our F.A.Q.</li>
			</ol>
			<p>
				And if you'd like to chat with us and try out an event, you can always grab time on our calendar when it's convenient for you.
			</p>
		</div>
	)
}

const OurClientsGet = () => {
	return (
		<div>
			<h2>
				Membership includes:
			</h2>
			<ol style={{textAlign: 'left'}}>
				<li>Access to all of our events, including seasonal events and celebrity events</li>
				<li>Customized events for your team</li>
				<li>No limits on headcount (everyone is welcome)</li>
				<li>A trained Emcee and production support on every event</li>
				<li>Around-the-clock white glove support</li>
				<li>Global-friendly logistics support (we ship goodies anywhere and everywhere!)</li>
				<li>Company-facing event pages and calendar management</li>
				<li>Internal marketing support (shared slack channels are our favorite)</li>
				<li>Year-round event programming (book out to 2023, if you wanna roll like that)</li>
			</ol>
		</div>
	)
}

const WhoAreWe = (data) => {
	return(
		<div>
			<div style={{}}>
				<h2>The future of remote work isn't one size fits all.</h2>
				<p>
					We provide customized event programming for every team based on corporate values, team size, geography, hiring and retention goals,and budget. And, we provide white-glove service to each and every one of our members.
				</p>
				<p>
					We'll tell you more about membership below. 😉
				</p>
			</div>
			<div style={{}}>
				<h2>
					Let us do what we do best.
				</h2>
				<p>
					We develop and deliver engaging, remote-first events from ideation to event-day.
				</p>
				<p>
					We manage invites and calendars, handle communications, ship goodies domestically and internationally, and curate event programming for your unique team.
				</p>
			</div>
			<div style={{}}>
				<h2>
					We have events you won't find anywhere else
				</h2>
				<p>
					Visit an artist's studio. Learn to pick a lock or crack a safe. Play a unique game developed for your team.
				</p>
				<p>
					You'll never run out of fresh options in our curated library of culinary classes, skill-developing workshops, seasonal events, games, creative speakers, and more.
				</p>
			</div>
		</div>
	)
}

const EventIdeas = () => {
	return (
		<div>
			<h2 id="eventIdeas">Event ideas</h2>
			<div style={{textAlign: 'left'}}>
				<p>
					Mini Exhibitions events are expert-led (and emcee-hosted) – and designed to be inclusive, interactive, and fun.
				</p>
				<p>
					When you’re ready to try an event, some of our most popular events are:
				</p>
				<ul style={{marginLeft: '2rem'}}>
					<li>🤓 Team up and go head-to-head in a competitive, live-hosted trivia game</li>
					<li>🔒 Learn the history and hands-on technique of lockpicking</li>
					<li>🍓 Learn to make a delicious, artisanal fruit jam from scratch</li>
					<li>🧑‍🎨 Get creative and make collages with your team</li>
				</ul>
				<br />
				<p>
					And in case you couldn’t tell, we’re proud of our off-the-beaten-track events, like:
				</p>
				<ul style={{marginLeft: '2rem'}}>
					<li>🍞 Bake a delicious Irish soda bread to enjoy with homemade compound butter</li>
					<li>🎤 Test your spelling skills against your colleagues in a live-hosted spelling bee</li>
					<li>🍵 Brew and taste Garam Chai at home with a tea expert</li>
					<li>🪡 Make your own plushie creations and learn the fuzzy art of needle felting</li>
				</ul>
			</div>
		</div>
	)
}

const PricingSection = (data) => {
	return (
		<div>
			<h2 id="pricing">Pricing</h2>
			<p>Events can be purchased a la carte or as part of a subscription membership.</p>
			<PricingTable pricing={data.team.pricing} />
			<br />
			<p>
				Some events require add-ons to be shipped to team members (things like 🧀 charcuterie boards, 🔒 lockpicking kits, and 🧱 clay modeling materials!). Those add-ons are billed separately, per attendee.
			</p>
			<p>
				We're also delighted to help ship non-required, extra goodies (like 🥨 snacks for your trivia game or 🥫 groceries for your cooking class) if it makes life easier for you and your team! Those optional add-ons are also billed separately, per attendee.
			</p>
		</div>
	)
}

const OutroCta = () => {
	return (
		<div>
			<h1>Let's set up your first event!</h1>
			<p>
				Ready to set up your first event? Or, want to ask us some questions to see if it's a fit?
			</p>
			<ul style={{textAlign: 'left', marginLeft: '4rem'}}>
				<li>
					📞&ensp;Book time with us on our <a href={calendlyUrl} target="_blank">calendar</a> whenever it’s convenient for you, or
				</li>
				<li>
					✉️&ensp;Send us a quick <a href={`mailto:${contactLinks.email}`} target="_blank">email</a>
				</li>
			</ul>
		</div>
	)
}
const NewLeadOverview = (data) => {
	<Helmet>
		<title>{data.team.name} | Mini Exhibitions</title>
	</Helmet>
	
	return (
		<div className='container white shadow' style={{
			fontSize: '1.25rem',
			lineHeight: '1.25',
		}}>
			<Intro team={data.team} />
			<ContentsOne team={data.team} />
			{/* <WhoAreWe /> */}
			<OurClientsGet />
			<EventIdeas />
			<br />
			<br />
			<BookACallButton />
			<PricingSection team={data.team} />
			<OutroCta />
			<h1 id="faq">Frequently Asked Questions</h1>
			<Faq />
			<br />
			<br />
			<BookACallButton />
		</div>
	)
}

const ShareableNewLeadOverviewContent = () => {
	const { teamId } = useParams();
	const dispatch = useDispatch()
	const teamData = useSelector(selectTeam)
	// const [modalOpen, setModalOpen] = useState(false)
	const states = ['Success', 'Error']

	window.teamData = teamData;
	console.log("teamData: ")
	console.log(teamData)
  
  const loadStatus = useSelector(state => state.team.status)
  const error = useSelector(state => state.team.error)

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchTeam(teamId))
    }
  }, [loadStatus, dispatch])
	
	console.log("`teamData` inside Intro:")
	console.log(teamData)

	return {
		Success: (
			<NewLeadOverview team={teamData.team} />
		),
		Pending: (
			<div>
				<Helmet>
					<title>Mini Exhibitions | Loading...</title>
				</Helmet>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<div>
				<Helmet>
					<title>Page not found | Mini Exhibitions</title>
				</Helmet>
				<h1>Hmm, we didn't find anything here 🤔</h1>
			</div>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 

}

const ShareableNewLeadOverviewPage = () => {
	return (
		<div>
			<Header />
			<ShareableNewLeadOverviewContent />			
		</div>
	)
}

export default ShareableNewLeadOverviewPage