import React, { useState, useEffect } from 'react'
import Header from '../navigation/Header.js' 
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProgram, selectProgram, setProgram } from '../../features/events/programSlice'
import { useParams } from "react-router-dom"
import AddAttendeeModal from './AddAttendeeModal'
import { openModal } from '../../features/modals/modalSlice'
	
	
const AddAttendeeButton = ({ clickHandler }) => {
	return(
		<a href="#" className="button small" onClick={clickHandler} style={{margin: '2rem auto'}}>
			Register
		</a>
	)	
}

const ViewEventInfoButton = ({ eventId }) => {
  const eventUrl = `/event/${eventId}`
	
	return (
		<a href={eventUrl} className="button small" style={{ margin: '2rem auto' }}>
			More info
		</a>
	)
}

const TimeSlotMiniEventListing = (props) => {
  const event = props.event
  const eventId = event.id
	
	const [modalOpen, setModalOpen] = useState(false)
	const handleAddAttendeeClick = (e) => {
		e.preventDefault();
		if (modalOpen) {
			setModalOpen(false);
		} else {
			setModalOpen(true);
		}
	}
	

  return (
    <div style={{
			border: '1px solid #e6e3db',
			height: '100%',
			padding: '0 1rem',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between'
		}}>
			<div>
				<h4>{event.title}</h4>
				<p style={{fontSize: '1.125rem'}}>
					{event.brief_desc}
				</p>
			</div>

			<div>
				<ViewEventInfoButton eventId={eventId} />			
				&emsp;
				<AddAttendeeButton clickHandler={handleAddAttendeeClick} />
			</div>
			{ modalOpen ? <AddAttendeeModal eventId={eventId} setModalOpen={setModalOpen} useAddressForm={event.requires_addresses} /> : '' }
    </div>
  )
}

const ProgramTimeSlot = (props) => {
  const timeSlot = props.timeSlot

  return (
    <div style={{marginBottom: '3rem'}}>
      <div>
        <h3 style={{}}>
					{timeSlot.time}
				</h3>
      </div>

      <div className='grid-2' style={{ 
				alignItems: 'baseline',
				columnGap: '2rem',
				gridAutoRows: '1fr' 
			}}>
        {timeSlot.events.map((item, i) => { return(
            <TimeSlotMiniEventListing
              key={item.id} 
              event={item} 
            />
          )
        })}
      </div>
    </div>
  )
}

const ShareableProgramPage = () => {
	const { programId } = useParams();
	const dispatch = useDispatch()
	const programData = useSelector(selectProgram)
	const [modalOpen, setModalOpen] = useState(false)
	const states = ['Success', 'Error']
  
  const loadStatus = useSelector(state => state.program.status)
  const error = useSelector(state => state.program.error)

	useEffect(() => {
    if (loadStatus === 'Idle') {
      dispatch(fetchProgram(programId))
    }
  }, [loadStatus, dispatch])

	return {
 		Success: (
			 <div>
				<Header />
				<div className='container white border'>
					<Helmet>
						<title>{programData.program.title} | Mini Exhibitions</title>
					</Helmet>
					<h1>{programData.program.title}</h1>
					<p>{programData.program.intro}</p>
					<dl>
						<div>
							<dt>Date</dt>
							<dd>{programData.program.date}</dd>
						</div>
					</dl>
					
					<br />
					<br />
          
          {programData.program.timeSlots.map((item, i) => { return(
              <ProgramTimeSlot
                key={i} 
                timeSlot={item} 
              />
            )
          })}
				</div>
			</div>
		),
		Pending: (
			<div>
				<Header />
				<Helmet>
					<title>Mini Exhibitions | Loading...</title>
				</Helmet>
				<h1>Loading...</h1>
			</div>
		),
		Error: (
			<div>
				<Header />
				<Helmet>
					<title>Program not found | Mini Exhibitions</title>
				</Helmet>
				<h1>Hmm, we didn't find an event program for you here 🤔</h1>
			</div>
		)
	}[states.includes(loadStatus) ? loadStatus : 'Pending'] 
}
export default ShareableProgramPage 